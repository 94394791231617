import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import userServices from "services/httpService/userAuth/userServices";
import Skelton from "components/Card/Skelton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
  },
  header: {
    backgroundColor: "#000",
    color: "#fff",
    padding: theme.spacing(1),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    color: "#fff",
    marginLeft: theme.spacing(0.5),
  },
  shopperList: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  shopperItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", // Align items vertically
    fontSize: "1rem",
    color: "#333",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5), // Add small gap between items
    cursor: "pointer",
  },
  shopperName: {
    marginRight: theme.spacing(1), // Control spacing between name and points
    flex: 1, // Allow name to take available space
    textAlign: "left",
  },
  shopperPoints: {
    flexShrink: 0, // Prevents points from shrinking, keeping them closer to the name
    textAlign: "right",
  },
}));

function TopShopper() {
  const [shopperList, setShopperList] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const { data, isLoading, isError } = useQuery("topSellers", () =>
    userServices.commonGetService("/topShopper")
  );

  const topSellers = useQuery(
    "userStatus",
    () => userServices.commonGetService(`/topShopper`),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        setShopperList(data.data);
      },
    }
  );

  if (isLoading) return <Skelton />;
  if (isError) return <Typography>Error fetching data</Typography>;

  return (
    <Container className={classes.root}>
      <Typography variant="h7" className={classes.header}>
        ouiiwe points: (op)
        <Tooltip
          title="Earn points each time you shop from a small business. Prizes are up for grabs for the top 3 shoppers at the end of each month. This page shows the top 20 ouiiwe shoppers."
          placement="top"
          leaveTouchDelay={30}
          enterTouchDelay={5}
          arrow
        >
          <IconButton className={classes.infoIcon} size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>

      <div className={classes.shopperList}>
        {/* Heading Row */}
        <div className={classes.shopperItem}>
          <span className={classes.shopperName}>
            <b>Shoppers</b>
          </span>
          <span className={classes.shopperPoints}>
            <b>op</b>
          </span>
        </div>

        {/* Shopper List */}
        {shopperList.map((seller) => (
          <div
            key={seller._id}
            className={classes.shopperItem}
            onClick={() => history.push(`/shopperview/${seller._id}`)}
          >
            <span className={classes.shopperName}>{seller.fname}</span>
            <span className={classes.shopperPoints}>{seller.sellerPoints}</span>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default TopShopper;
