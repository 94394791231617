module.exports = {
  endPoint: "https://ouiiwe.com.au/api",

  PUBLIC_KEY_Stripe:
    "pk_live_51JCkmNISlIOjXIQCaDvlCT75kx8oRcXzr4HslYWi8VzSTSp3hfIPVnw984vI3P7Ur4WqFZrEt0QbCNbEKgYuzcrl00Y00r2H51",

  s3BucketUrl: "https://ouiiwe.com.au/readfiles/",

  ////s3BucketUrl: 'https://latestlocaldeals.s3.amazonaws.com/',

  smallbusiness: "smallbusiness",
  shopper: "shopper",
  guid: "a938a9f2-8007-460b-a594-c491058ea931",

  serviceCharges: 1.99,

  ////testing
  ///endPoint: "http://localhost:5001/api",
  ///PUBLIC_KEY_Stripe:
  ////   "pk_test_51JoCbnDvBGCF0IMKx49D3V5PCZzTGDxicaBBezkhZfX6P7W1QTJVgdpd9WOGAHTGGVGaLoHP0fv0HMoLLKj60F2f00wAbHS8V5",
};
