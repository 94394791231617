import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import Container from '@material-ui/core/Container';
import { useParams } from 'react-router-dom';
import { PUBLIC_KEY_Stripe } from 'config/config';
import {
  CardElement,
  useElements,
  Elements,
  useStripe,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import PaymentPage from '../../components/Card/PaymentPage';

const PUBLIC_KEY = PUBLIC_KEY_Stripe;

const stripeTestPromise = loadStripe(PUBLIC_KEY);
function Payment() {
  return (
    <Elements stripe={stripeTestPromise}>
      <Container maxWidth='sm'>
        <div className='mt-20 font-bold text-black text-xl antialiased  flex content-center items-center justify-center'>
          Payment
        </div>
        <PaymentPage />
      </Container>
    </Elements>
  );
}

export default Payment;
