import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

function Howitwork() {
  return (
    <div className=' py-8'>
      <Container>
        <div className='text-black text-center font-bold text-xl'>
          How ouiiwe works
        </div>

        <div className='text-black  font-bold text-lg mt-8 mb-4'>
          How to sell.
        </div>
        <p className='text-black text-center text'>
          1) First you will need a seller account which you can create by
          transforming your existing shopper account into small business
          account. While logged in, in the side bar, click small business and
          follow the steps, you can either use your ABN or request to become a
          seller.
        </p>
        <div className='flex justify-center items-center my-4'>
          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/ueGZI-mBWR4'
          ></iframe>
        </div>

        <div className='text-black font-bold text-lg mt-8 mb-4'>
          Listing a local service.
        </div>

        <div className='flex justify-center items-center my-4'>
          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/-Eeo63EoCSU'
          ></iframe>
        </div>

        <div className='text-black font-bold text-lg mt-8 mb-4'>
          Promote your products
        </div>

        <div className='flex justify-center items-center my-4'>
          <iframe
            width='420'
            height='315'
            src='https://www.youtube.com/embed/U06T_ahutsA'
          ></iframe>
        </div>

        <p className='text-black text mt-10 text'>
          Our fees:
          <br /> our platform connects sellers with customers, facilitates
          sales, and charges a 5% commission fee while paying out 95% of the
          sale price to the seller. The seller will be paid out with 24hr of
          completing the order and us confirming
        </p>

        {/* <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='center-styl text-white'>Shopper </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-white text-center mt-4 font-medium'>
              Support and Shop with local sellers Anywhere.
            </p>
          </Grid>
          <ul className='list-none mt-6'>
            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-map-marked-alt'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Search anything using your current location.
                  </h4>
                </div>
              </div>
            </li>

            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='far fa-clock'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Within seconds, access what near you have to offer.
                  </h4>
                </div>
              </div>
            </li>

            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-shopping-bag'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Either buy or view promoted products uploaded by Business
                    owners.
                  </h4>
                </div>
              </div>
            </li>

            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-home'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Once purchased, business owner will receive, process, and
                    send your order to your shipping address.
                  </h4>
                </div>
              </div>
            </li>
            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-comment'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Have your say! Display your purchased items by leaving a
                    review for the seller.
                  </h4>
                </div>
              </div>
            </li>
          </ul>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='center-styl text-white mt-10'>Seller </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-white text-center mt-4 font-medium'>
              Sell or promote your products and services anywhere.
            </p>
          </Grid>
          <ul className='list-none mt-6'>
            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-camera'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    At anytime, anywhere, snap, upload to sell or promote
                    products, with your location.
                  </h4>
                </div>
              </div>
            </li>

            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-truck'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Receive, process, and post/ship your customers’ orders.
                  </h4>
                </div>
              </div>
            </li>

            <li className='py-2'>
              <div className='flex items-center'>
                <div>
                  <span className='text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-blue-200 mr-3'>
                    <i className='fas fa-money-bill-wave'></i>
                  </span>
                </div>
                <div>
                  <h4 className='text-white'>
                    Get your business paid within 24 hrs of us confirming your
                    customer’s order is on its way.
                  </h4>
                </div>
              </div>
            </li>
          </ul>
        </Grid> */}
      </Container>
    </div>
  );
}

export default Howitwork;
