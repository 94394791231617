import React, { useState } from "react";
import ProfileHeader from "components/ProfileHeader";
import { s3BucketUrl } from "config/config";
import { localStorageData } from "services/auth/localStorageData";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
function Header() {
  const location = useLocation();
  const { businessName, currentRole } = useSelector((state) => state.userRole);
  const [sellerPoints, setSellerPoints] = useState(0);

  console.log(businessName);

  const ShopperDealshere = useQuery(
    "userdealshere",
    () =>
      userServices.commonGetService(`/getFollowing/${localStorageData("_id")}`),

    {
      enabled: !!localStorageData("_id"),
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        console.log("--------------------s", data.data);
        console.log("--------------------s", data.data.findUser.sellerPoints);
        setSellerPoints(data.data.findUser.sellerPoints);
      },
    }
  );

  return (
    <>
      <ProfileHeader
        name={
          localStorageData("_id")
            ? currentRole == "smallbusiness"
              ? businessName
              : localStorageData("username")
            : ""
        }
        upload={true}
        sellerPoints={sellerPoints}
        search={true}
        follow={false}
        // img={localStorageData('pic')}
        img={
          localStorageData("_id")
            ? s3BucketUrl + localStorageData("pic")
            : "https://ui-avatars.com/api/?name=John+Doe"
        }
      />
    </>
  );
}

export default Header;
