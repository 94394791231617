import React from 'react';
import ReviewCard from 'components/Card/ReviewCard';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Divider from '@material-ui/core/Divider';
import history from './../../history';
import Category from 'components/Data/index';
import { useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import Skelton from 'components/Card/Skelton';

function ReviewPage() {
  const [filterData, setFilterData] = React.useState([]);

  function filterProductBycategory(filterTag) {
    let filteredData = filterData.filter(
      (values) => values.category === filterTag
    );

    if (filteredData != '') {
      return (
        <div className='mb-2'>
          <h2 className='font-bold text-2xl'>
            {filterTag} &nbsp;
            <IconButton aria-label='delete' size='small'>
              <ArrowForwardIcon fontSize='inheit' />
            </IconButton>
          </h2>
          <Divider />
          <div className='row1'>
            <ReviewCard data={filteredData} />
          </div>
        </div>
      );
      // return filteredData[0].docs;
    }
  }

  const { isLoading, error, data } = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/fetchReviewscategory`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        // setrows(data.data);
        setFilterData(data.data);
        // console.log(data.data);
      },
    }
  );

  return (
    <div>
      <main>
        <section className='relative w-full py-18 mt-28 h-full  min-h-screen '>
          <Container>
            {isLoading == false ? (
              <>
                {Category.map((s, i) => (
                  <>{filterProductBycategory(s)}</>
                ))}
              </>
            ) : (
              <>
                <Skelton />
                <Skelton />
                <Skelton />
                <Skelton />
              </>
            )}
          </Container>
        </section>
      </main>
    </div>
  );
}

export default ReviewPage;
