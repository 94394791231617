import React, { useState } from 'react';
import ProfileHeader from '../../components/ProfileHeader';
import Container from '@material-ui/core/Container';
import Table from 'components/Table';

import { localStorageData } from 'services/auth/localStorageData';

import userServices from 'services/httpService/userAuth/userServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from 'react-query';
import Header from 'components/ProfileHeader/Header';
function SmallBusiness() {
  const [orderList, setorderList] = useState([]);

  const { isLoading } = useQuery(
    'ordersData',
    () =>
      userServices.commonGetService(
        `/fetchordersforsmallbusiness/${localStorageData('_id')}`
      ),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        setorderList(data.data);
        console.log(orderList);
      },
    }
  );

  return (
    <div>
      <Header />

      <Container maxWidth='sm'>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {orderList.length != 0 || orderList != 'null' ? (
              <Table data={orderList} from={'smallbusiness'} />
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
}

export default SmallBusiness;
