import React from "react";
import Container from "@material-ui/core/Container";

import { useLocation } from "react-router-dom";
import moment from "moment";
import userServices from "../../services/httpService/userAuth/userServices";
import history from "./../../history";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { serviceCharges } from "config/config";

function OrderDetail() {
  const [open, setOpen] = React.useState(false);

  const [whoOpen, setwhoOpen] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = (value) => {
    setOpen(true);
    setwhoOpen(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();

  const {
    isError,

    isLoading,

    isSuccess,
    mutate,
  } = useMutation(
    (confirmOrder) =>
      userServices.commonPostService("/confirmOrder", confirmOrder),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        toast.success("Order Has been Confirmed");

        history.push("/");
      },
    }
  );

  const OrderComplete = useMutation(
    (completeOrder) =>
      userServices.commonPostService("/completeOrder", completeOrder),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        toast.success("Order Has been Completed");

        history.push("/admin");
      },
    }
  );

  const handleConfirmOrder = async () => {
    // let data = {
    //   id: localStorageData('_id'),
    //   orderId: location.state.detail._id,
    // };

    history.push({
      pathname: "/shipmentdetails",

      state: {
        orderId: location.state.detail._id,
        counterId: location.state.detail.counterId,
      },
    });
    ///// mutate(data);
  };

  const handleComplete = async () => {
    ///// mutate(data);

    let data = {
      orderId: location.state.detail._id,
    };

    OrderComplete.mutate(data);
  };

  console.log("------------------------------------------");

  console.log(location.state.detail);

  function Business() {
    return (
      <div>
        <div className="text subpixel-antialiased ml-6 mt-4 font-semibold underline	tracking-wide text-gray-600">
          Business Details
        </div>
        <div className="p-4 px-8 my-6 w-full">
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            {/* Name:{location.state.detail.business.businessName} */}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Address:{location.state.detail.shop.address}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Number:{location.state.detail.shop.contactNo}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Email:{location.state.detail.shop.email}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Date:{" "}
            {moment(location.state.detail.dateGenerated).format(
              "DD:MM:YYYY h:mm:ss"
            )}
          </div>
        </div>
      </div>
    );
  }

  function Shopper() {
    return (
      <div>
        <div className="text subpixel-antialiased ml-6 font-semibold underline	tracking-wide text-gray-600">
          Shopper Details
        </div>
        <div className="p-4 px-8 my-6 w-full">
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Name:{location.state.detail.customer.fname}{" "}
            {location.state.detail.customer.lname}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Address:{location.state.detail.address}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Number:{location.state.detail.contactNo}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Email:{location.state.detail.customer.email}
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Date:{" "}
            {moment(location.state.detail.dateGenerated).format(
              "DD:MM:YYYY h:mm:ss"
            )}
          </div>
        </div>
      </div>
    );
  }

  function AdminDetail() {
    return (
      <div>
        <div className="text subpixel-antialiased ml-6 font-semibold underline	tracking-wide text-gray-600">
          Admin Details
        </div>
        <div className="border-2 border-current border-solid p-4 px-8 my-6 w-full">
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600">
            Name: Patrick
          </div>

          {/* <div className='text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600'>
            Number:0466006171
          </div> */}
          <div className="text my-2 subpixel-antialiased overflow-ellipsis ml-6 font-semibold 	tracking-wide text-gray-600">
            Email:ouiiwe@outlook.com
          </div>
          <div className="text my-2 subpixel-antialiased overflow-ellipsis ml-6 font-semibold 	tracking-wide text-gray-600">
            Team:ouiiwe
          </div>
          <div className="text my-2 subpixel-antialiased ml-6 font-semibold 	tracking-wide text-gray-600"></div>
        </div>
      </div>
    );
  }

  // function getDialogContent() {
  //   if (whoOpen == 'foradmin') {
  //     AdminDetail();
  //   } else {
  //     if (whoOpen == 'smallbusiness') {
  //       Shopper();
  //     } else {
  //       Business();
  //     }
  //   }
  // }

  function getCoupenPrice() {
    let ActualCost =
      location.state.detail.productDetail[0].products.reduce(
        (sum, { quantity, nowPrice, postageFee }) =>
          sum + (quantity * nowPrice + postageFee),
        0
      ) + serviceCharges;

    let coupenPrice = ActualCost - location.state.detail.TotalPrice;

    if (coupenPrice > 5) return "Applied Coupon: " + coupenPrice.toFixed(1);
    else return "";
  }

  function getCoupenPriceForCalculation() {
    let ActualCost =
      location.state.detail.productDetail[0].products.reduce(
        (sum, { quantity, nowPrice, postageFee }) =>
          sum + (quantity * nowPrice + postageFee),
        0
      ) + serviceCharges;

    let coupenPrice = ActualCost - location.state.detail.TotalPrice;

    if (coupenPrice > 5) return coupenPrice;
    else return 0;
  }
  return (
    <div>
      <Container maxWidth="sm">
        <div className=" my-6">
          <div className="center-styl mt-20">
            Order Number: {location.state.detail.counterId}
          </div>
          <Business />
          <Shopper />

          <div className="text subpixel-antialiased ml-6 font-semibold underline	tracking-wide text-gray-600">
            Order Details
          </div>
          <div className="text subpixel-antialiased ml-6 font-semibold underline	tracking-wide text-gray-600">
            Order status: {location.state.detail.orderStatus}
          </div>
          <div className="table-stly">
            <table className="table-auto border-collapse border border-gray-800 my-6 p-4">
              <tbody>
                <tr>
                  <td className="border border-gray-600 p-4">Order </td>
                  {location.state.detail.type == "others" ? (
                    <>
                      <td className="border border-gray-600 p-4">colour </td>
                      <td className="border border-gray-600 p-4">size </td>
                      <td className="border border-gray-600 p-4">QT </td>
                      <td className="border border-gray-600 p-4">
                        Product name{" "}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border border-gray-600 p-4">Booking </td>
                      <td className="border border-gray-600 p-4">
                        Service name{" "}
                      </td>
                    </>
                  )}
                  <td className="border border-gray-600 p-4">Cost. </td>
                </tr>

                {location.state.detail.productDetail[0].products.map((item) => (
                  <>
                    <tr>
                      <td className="border border-gray-600 p-4">
                        {" "}
                        {location.state.detail.counterId}
                      </td>

                      {location.state.detail.type == "others" ? (
                        <>
                          <td className="border border-gray-600 p-4">
                            {" "}
                            {item.color}
                          </td>
                          <td className="border border-gray-600 p-4">
                            {" "}
                            {item.size}
                          </td>
                          <td className="border border-gray-600 p-4">
                            {" "}
                            {item.quantity}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="border border-gray-600 p-4">
                            {moment(
                              location.state.detail.availability.startTime
                            ).format("DD/MM/YYYY h:mm:ss")}
                            -
                            {moment(
                              location.state.detail.availability.endTime
                            ).format("DD/MM/YYYY h:mm:ss")}
                          </td>
                        </>
                      )}

                      <td className="border border-gray-600 p-4">
                        <a
                          onClick={() => {
                            history.push(
                              `/details/${item.data._id}/${item.data.productName}/${location.state.detail.productDetail[0].products.shopName}`
                            );
                          }}
                        >
                          {item.data.productName}
                        </a>
                      </td>
                      <td className="border border-gray-600 p-4">
                        {" "}
                        {item.nowPrice * item.quantity}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex  gap-4 mt-10">
            {location.state.from == "admin" ? (
              <>
                <button
                  className="btn-styl h-10"
                  type="button"
                  onClick={() => handleClickOpen("smallbusiness")}
                  // onClick={() => toast.warning('search commin soon')}
                >
                  Contact Customer
                </button>
                <button
                  className="btn-styl h-10"
                  type="button"
                  onClick={() => handleComplete()}
                  // onClick={() => toast.warning('search commin soon')}
                >
                  <span className="text-green-400">Complete Order</span>
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn-styl h-10"
                  type="button"
                  onClick={() => handleClickOpen("foradmin")}
                >
                  Contact Admin
                </button>
              </>
            )}
            {location.state.from == "smallbusiness" ? (
              <button
                className="btn-styl h-10"
                type="button"
                onClick={() => handleClickOpen("smallbusiness")}
                // onClick={() => toast.warning('search commin soon')}
              >
                Contact Customer
              </button>
            ) : (
              <button
                className="btn-styl h-10"
                type="button"
                onClick={() => handleClickOpen("shopper")}
                // onClick={() => toast.warning('search commin soon')}
              >
                Contact business
              </button>
            )}
          </div>

          <div className="flex  gap-4 mt-10">
            <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
              Product cost: ${" "}
              {location.state.detail.productDetail[0].products.reduce(
                (sum, { quantity, nowPrice }) => sum + nowPrice * quantity,
                0
              )}
            </div>

            <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
              Postage fee : $
              {location.state.detail.productDetail[0].products.reduce(
                (sum, { quantity, postageFee }) => sum + postageFee,
                0
              )}
            </div>

            {/* {location.state.detail.orderStatus != 'shipped' &&
            location.state.from == 'smallbusiness' ? null : (
              <div className='text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600'>
                Service Charges : ${serviceCharges}
              </div>
            )} */}

            {location.state.from == "shopper" ? (
              <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
                Service Charges : ${serviceCharges}
              </div>
            ) : null}
          </div>

          <div className="flex  gap-4 mt-10">
            {location.state.from === "smallbusiness" ? (
              <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
                Total cost: ${" "}
                {location.state.detail.productDetail[0].products
                  .reduce(
                    (sum, { quantity, nowPrice, postageFee }) =>
                      sum + (quantity * nowPrice + postageFee),
                    0
                  )
                  .toFixed(2)}
                {/* {location.state.detail.TotalPrice} */}
                {/* {location.state.detail.productDetail[0].products.reduce(
                  (sum, { quantity, nowPrice, postageFee }) =>
                    sum + (quantity * nowPrice + postageFee),
                  0
                ) + serviceCharges} */}
              </div>
            ) : (
              <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
                Total cost: $
                {(
                  location.state.detail.productDetail[0].products.reduce(
                    (sum, { quantity, nowPrice, postageFee }) =>
                      sum + (quantity * nowPrice + postageFee),
                    0
                  ) + serviceCharges
                ).toFixed(1)}
                {/* {location.state.detail.TotalPrice} */}
              </div>
            )}

            <div className="text-lg my-2 subpixel-antialiased  font-semibold 	tracking-wide text-gray-600">
              {getCoupenPrice()}
            </div>
            {location.state.detail.orderStatus != "shipped" &&
            location.state.from == "smallbusiness" ? (
              <button
                className="btn-styl h-10"
                type="button"
                onClick={handleConfirmOrder}
                // onClick={() => toast.warning('search commin soon')}
              >
                <span className="text-green-400">Complete</span>
              </button>
            ) : null}
          </div>
        </div>

        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          {/* <DialogTitle id='responsive-dialog-title'>
            {"Use Google's location service?"}
          </DialogTitle> */}
          <DialogContent>
            {whoOpen == "foradmin" ? (
              <AdminDetail />
            ) : whoOpen == "shopper" ? (
              <Business />
            ) : (
              <Shopper />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}

export default OrderDetail;
