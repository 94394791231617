import { shopper, smallbusiness } from "../../../config/config";

const initialState = {
  currentRole: shopper,
  smallbusinessPass: false,
  businessName: "Business Name",
};

function UpdateUserRoles(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "ChangeCurrentRole":
      return {
        ...state,

        currentRole: payload,
      };

    case "ChangesmallbusinessPass":
      return {
        ...state,

        smallbusinessPass: payload,
      };

    case "fetchbusiness":
      return {
        ...state,

        businessName: payload,
      };

    default:
      return state;
  }
}

export default UpdateUserRoles;
