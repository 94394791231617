import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Table from 'components/Table';
import { localStorageData } from 'services/auth/localStorageData';
import { useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShopperButton from 'components/ProfileHeader/ShopperButton';
import Header from 'components/ProfileHeader/Header';
function OrdersShopper() {
  const { isLoading, data } = useQuery('ordersData', () =>
    userServices.commonGetService(
      `/fetchordersfromShopper/${localStorageData('_id')}`
    )
  );

  return (
    <div>
      <Header />
      <ShopperButton />
      <Container maxWidth='sm'>
        {isLoading ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <Table data={data.data} from={'shopper'} />
        )}
      </Container>
    </div>
  );
}

export default OrdersShopper;
