import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FormikConsumer, useFormik } from 'formik';
import * as Yup from 'yup';
import history from './../../../history';
import Address from 'components/Address';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateModel from 'components/CustomModel/DateModel';
import userServices from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import moment from 'moment-timezone';
import { localStorageData } from 'services/auth/localStorageData';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
function SellServices() {
  const [lat, setlat] = useState('');
  const [long, setlong] = useState('');
  const [availability, setAvailability] = useState([]);

  const isLoading = false;

  // console.log('all=> ', availability);

  const [address, setaddress] = useState('');
  function changeaddress(value) {
    formik.setFieldValue('address', value);
  }

  const formik = useFormik({
    initialValues: {
      productName: '',
      pics: [],
      shopName: '',
      // postCode: '',
      amount: '',
      wasPrice: '',
      nowPrice: '',
      caption: '',

      address: address,
      productUrl: '',
      expireDate: '',
      postageFee: 0,
      category: '',
      // username: "",
    },
    validationSchema: Yup.object().shape({
      productName: Yup.string()
        .min(3, 'Must be more than 4 characters')
        .required('Required'),
      ///pics: Yup.array().min(1, "please attach at least one picture"),
      address: Yup.string().required('Required'),
      expireDate: Yup.string().required('Required'),
      nowPrice: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append('pics', values.pics);
      formData.append('users', localStorageData('_id'));
      Object.keys(values).forEach(function (key) {
        if (key != 'pics' || key != 'expireDate') {
          formData.append(key, values[key]);
        }
      });
      availability.forEach((item) =>
        formData.append('availability[]', JSON.stringify(item))
      );
      // finalcolors.forEach((item) => formData.append("color[]", item));

      formData.append('lat', lat);
      formData.append('long', long);

      // formData.append(
      //   'expireDate',
      //   moment(values.expireDate).format('YYYY-MM-DD')
      // );

      mutate(formData);

      // values.lat = lat;
      // values.long = long;
      // values.availability = availability;

      // values.users = localStorageData('_id');

      // mutate(values);
      // console.log(values);
    },
  });

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('output');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);

      formik.setFieldValue('pics', e.target.files[0]);
    }
  };

  const { mutate } = useMutation(
    (postSellService) =>
      userServices.commonPostService('/sellservices', postSellService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success('deal successfully posted');
        history.push('/');
      },
    }
  );

  return (
    <div className='min-h-screen mt-28 '>
      <form
        className=' relative mb-3 '
        autocomplete='off'
        onSubmit={formik.handleSubmit}
      >
        <Container maxWidth='sm'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className='center-styl'>
                <img
                  src='https://ui-avatars.com/api/?name=NA'
                  alt='img'
                  id='output'
                  className='w-40 h-40 rounded'
                />
              </div>
              <div className=' center-styl mt-2'>
                <label className='btn-styl'>
                  <input type='file' onChange={onChangeHandler} />
                  Custom Upload
                </label>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='center-styl'>
                <input
                  type='text'
                  name='productName'
                  id='productName'
                  className='input-styl h-12 px-2 py-2'
                  placeholder='Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // value={formik.values.productName}
                />
              </div>
              {formik.touched.productName && formik.errors.productName ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.productName}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <div className=''>
                <Address
                  label='Address'
                  setaddress={setaddress}
                  setlat={setlat}
                  changeaddress={changeaddress}
                  setlong={setlong}
                  ////  address={address}
                  // onChange={(e) => setaddress(e.target.value)}
                />
              </div>
              {formik.touched.address && formik.errors.address ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.address}
                </div>
              ) : null}
            </Grid>

            <DateModel
              availability={availability}
              setAvailability={setAvailability}
            />

            <div>
              {availability.map((data, index) => {
                return (
                  <div>
                    <table className='table-auto border-collapse border border-gray-800 my-6 p-4'>
                      <thead>
                        {data.date}
                        <tr>
                          <th className='border border-gray-600 p-4'>
                            Start Time
                          </th>
                          <th className='border border-gray-600 p-4'>
                            End Time
                          </th>
                          {/* <th className='border border-gray-600 p-4'>Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.timing.map((item, i) => (
                          <tr className=' '>
                            <td className='border border-gray-600 p-4'>
                              {moment(item.startTime).format(
                                'MM/DD/YYYY h:mm A '
                              )}
                            </td>
                            <td className='border border-gray-600 p-4'>
                              {moment(item.endTime).format(
                                'MM/DD/YYYY h:mm A '
                              )}
                            </td>

                            {/* <td className='border border-gray-600 p-4'>
                            <button onClick={() => handledDelete(i)}>
                              <i className='fas fa-trash'></i>
                            </button>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>

            <Grid item xs={12}>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='text'
                  className='input-styl h-12 px-2 py-2'
                  placeholder='Shop Name'
                  name='shopName'
                  id='shopName'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shopName}
                />
              </div>
              {formik.touched.shopName && formik.errors.shopName ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.shopName}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <label for='colors'>Expire date:</label>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='date'
                  placeholder='Expire date'
                  id='expireDate'
                  // value='2017-06-01'
                  className='input-styl h-12 px-2 py-2'
                  name='expireDate'
                  format='DD-MM-YYYY'
                  onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.expireDate}
                />
              </div>
              {formik.touched.expireDate && formik.errors.expireDate ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.expireDate}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='number'
                  name='nowPrice'
                  ////  min='1'
                  id='nowPrice'
                  className='placeholder-gray-600 text-gray-600 bg-white rounded-md text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-2 border-color-primary h-12 px-2 py-2 '
                  placeholder='Product Price'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nowPrice}
                />
              </div>
              {formik.touched.nowPrice && formik.errors.nowPrice ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.nowPrice}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='number'
                  name='wasPrice'
                  id='wasPrice'
                  ////   min='1'
                  className='placeholder-gray-600 text-gray-600 bg-white rounded-md text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-2 border-color-secondary h-12 px-2 py-2 '
                  placeholder='Sale price (optional)'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.wasPrice}
                />
                <Tooltip
                  leaveTouchDelay={30}
                  className='text-black'
                  enterTouchDelay={5}
                  title='use this option to list on sale product'
                  placement='top'
                  style={{ marginBottom: 15, marginLeft: 10 }}
                >
                  <HelpOutlineIcon fontSize='small' />
                </Tooltip>
              </div>
              {formik.touched.wasPrice && formik.errors.wasPrice ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.wasPrice}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <div className=' flex content-center items-center justify-center'>
                <textarea
                  type='text'
                  name='caption'
                  id='caption'
                  className='input-styl  px-2 py-2'
                  placeholder='Description '
                  rows='4'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.caption}
                ></textarea>
              </div>
              {formik.touched.caption && formik.errors.caption ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.caption}
                </div>
              ) : null}
            </Grid>
            {/* 
            <Grid item xs={12}>
              <label for='colors'>Address:</label>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='number'
                  name='postageFee'
                  ////  min='1'

                  id='postageFee'
                  className='input-styl h-12 px-2 py-2'
                  placeholder='Address'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postageFee}
                />
              </div>
              {formik.touched.postageFee && formik.errors.postageFee ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.postageFee}
                </div>
              ) : null}
            </Grid> */}

            <Grid item xs={6}>
              <div className=' flex content-center items-center justify-center'>
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <button className='btn-styl' type='submit'>
                    Upload Service
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </form>
    </div>
  );
}

export default SellServices;
