import { withRouter } from 'react-router-dom';
import history from './history';
import jwt_decode from 'jwt-decode';

import { useEffect } from 'react';
import userServices from './services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';

import { useMutation } from 'react-query';
import {
  localStorageData,
  updateLocalstorageToken,
} from './services/auth/localStorageData';
const AuthVerifyComponent = () => {
  const SESSION_IDEL_MINUTES = 1;

  // history.listen(() => {  // <--- Here you subscribe to the route change
  //     // if (localStorage.getItem("JWT_Token")) {
  //     //     const jwt_Token_decoded = Jwt_Decode(localStorage.getItem("JWT_Token"));
  //     //     console.log(jwt_Token_decoded.exp * 1000);
  //     //     console.log(Date.now());
  //     //     if (jwt_Token_decoded.exp * 1000 < Date.now()) {
  //     //         localStorage.clear();
  //     //     } else {
  //     //         initialstate.user = jwt_Token_decoded;
  //     //     }
  //     // }

  //     alert("dd")
  // });

  // const handleOnIdle = (event) => {
  //   alert('user Idle logout');
  //   console.log('user is idle', event);
  //   console.log('last active', getLastActiveTime());
  // };

  // const handleOnActive = (event) => {
  //   alert('user do something');
  //   console.log('user is active', event);
  //   console.log('time remaining', getRemainingTime());
  // };

  // const handleOnAction = (event) => {
  //   console.log('user did something', event);
  // };

  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
  //   onIdle: handleOnIdle,
  //   onActive: handleOnActive,
  //   onAction: handleOnAction,
  //   debounce: 500,
  // });

  const { mutate } = useMutation(
    (refreshToken) =>
      userServices.commonPostService('/refreshToken', refreshToken),
    {
      onError: (error) => {
        /// alert('---------------errr--------');
        ////  toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        ////// alert('---------------success--------');

        console.log(data.data);

        updateLocalstorageToken(data.data);
        ////// console.log(data.data);
        //// toast.success(' Business Has been confirmed');

        //////history.push('/ordersmallbusiness');
      },
    }
  );
  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem('localdealtoken'));

    if (localData && localData.hasOwnProperty('token')) {
      const { exp } = jwt_decode(localData.token);
      const expirationTime = exp * 1000 - 60000;

      if (Date.now() >= expirationTime) {
        localStorage.removeItem('localdealtoken');
        toast.error('Your session has expired. please log in');
        history.push('/signin');
        // set LocalStorage here based on response;
      } else {
        let data = {
          user_id: localStorageData('_id'),
          email: localStorageData('email'),
        };
        mutate(data);
      }
    }

    ////  alert("dd")
  }, [history.location.key]);
  return <div></div>;
};

export default withRouter(AuthVerifyComponent);

// import { useEffect } from 'react'
// import { withRouter } from 'react-router-dom'

// import history from './history';
// const AuthVerifyComponent = ({ history }) => {
//     useEffect(() => {
//         ////console.log('> Router', history.action, history.location])
//     }, [history.location.key])

//     return null
// }

// AuthVerifyComponent.propTypes = { history: history }

// export default withRouter(AuthVerifyComponent)
