import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


// import TotalCustomers from './Components/TotalCustomers';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useQuery, useMutation } from 'react-query';
import userServices from '../services/httpService/userAuth/userServices';

import ErrorService from '../services/formatError/ErrorService';
const columns = [
  {
    id: 'counterId',
    label: 'Request Id',
    minWidth: 170,
  },
  {
    id: 'businessName',
    label: 'Business Name',
    minWidth: 170,
  },
  //// { id: 'productDetail', label: 'Product Name', minWidth: 170 },
  { id: 'typeOfbusiness', label: 'Type Ofbusiness', minWidth: 170 },
  { id: 'socialMedia', label: 'social Media', minWidth: 170 },
  {
    id: 'website',
    label: 'website',
    minWidth: 170,
  },
  { id: 'aboutBusiness', label: 'aboutBusiness', minWidth: 170 },
  { id: 'reason', label: 'Why ouiiwe', minWidth: 170 },

  { id: 'status', label: 'status', minWidth: 170 },

  { id: 'buttonAccept', label: 'Action', minWidth: 170 },
];

function createData(no, email, user) {
  return { no, email, user };
}

///const rows = [createData("no", "name@gmail.com", "username")];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});
function BusinessRequest() {
  const [rows, setrows] = useState([]);
  const { isLoading, error, data } = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/fetchbusinessrequest`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setrows(data.data);
        console.log(data.data);
      },
    }
  );

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [rows, setrows] = useState(orderList);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { mutate } = useMutation(
    (confirmOrder) =>
      userServices.commonPostService('/confirmbusiness', confirmOrder),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success(' Business Has been confirmed');

        //////history.push('/ordersmallbusiness');
      },
    }
  );

  const handleClick = (i) => {
    // history.push({
    //   pathname: '/orderdetail',
    //   state: { detail: rows[i], from: 'admin' },
    // });
  };

  const handleAccept = (i) => {
    const r = window.confirm('Are you sure want to approve?');
    if (r == true) {
      let newrows = rows;

      newrows[i].status = 'accepted';

      setrows([...newrows]);
      mutate({ requestId: rows[i]._id });
    }

    // history.push({
    //   pathname: '/orderdetail',
    //   state: { detail: rows[i], from: 'admin' },
    // });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          {/* <TotalCustomers /> */}
        </Grid>
      </Grid>

      <br />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.email}
                      onClick={() => handleClick(i)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        if (column.id == 'buttonAccept') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                onClick={() => handleAccept(i)}
                                style={{ background: 'orange' }}
                              >
                                Accept
                              </Button>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default BusinessRequest;
