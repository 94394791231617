import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { localStorageData } from "services/auth/localStorageData";
import Maincard from "components/Card/Maincard";
import userServices from "services/httpService/userAuth/userServices";
import { useQuery } from "react-query";
import Skelton from "components/Card/Skelton";
import BusinessButton from "components/ProfileHeader/BusinessButton";
import Header from "components/ProfileHeader/Header";

import history from "./../../history";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

function SmallBusiness() {
  const [deals, setdeals] = useState([]);

  const [story, setStory] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ShopperDeals = useQuery(
    "userStatus",
    () =>
      userServices.commonGetService(
        `/fetchbyvistorid/${localStorageData("_id")}`
      ),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        console.log(
          "data.data[0]data.data[0]data.data[0]data.data[0]data.data[0]",
          data.data[0]
        );
        setdeals(data.data[0].deals.reverse());
        setStory(data.data[0].sellers.story);
      },
    }
  );

  useEffect(() => {
    if (localStorageData("_id")) {
      userServices
        .commonGetService(`/isSellerExist/${localStorageData("_id")}`)
        .then(
          (response) => {
            if (response.data != "sellerNotExist") {
              //  dispatch({
              //    type: "ChangesmallbusinessPass",
              //    payload: true,
              //  });
              //  dispatch({
              //    type: "fetchbusiness",
              //    payload: response.data,
              //  });
            } else {
              /// alert('seller not  exist');

              history.push("approvebusiness");
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    }
  }, []);

  return (
    <div>
      <Header />

      <BusinessButton />
      <Container maxWidth="lg">
        <button
          className="underline text-black font-semibold p-4 "
          onClick={handleClickOpen}
        >
          Story
        </button>
        <div className="row1 mt-4">
          {ShopperDeals.isLoading === false ? (
            <Maincard cardData={deals} />
          ) : (
            <div className="row1 ">
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div>

        <button
          className="fixed bottom-10 right-10 underline w-18 h-18 p-4"
          onClick={() => history.push("/reviewbusiness")}
        >
          {/* <i className='fas fa-edit' /> */}
          Reviews
        </button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Business’ story"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="text-center my-10">{story}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}

export default SmallBusiness;
