import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import { localStorageData } from "services/auth/localStorageData";
import { useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import Skelton from "components/Card/Skelton";
import Maincard from "components/Card/Maincard";
import ShopperButton from "components/ProfileHeader/ShopperButton";
import Header from "components/ProfileHeader/Header";
import ReviewCard from "components/Card/ReviewCard";

function Shopper() {
  const [deals, setdeals] = useState([]);
  const [review, setReview] = useState([]);

  const ShopperDeals = useQuery(
    "userStatus",
    () =>
      userServices.commonGetService(
        `/fetchbyuserid/${localStorageData("_id")}/shopper`
      ),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        console.log(data.data);
        setdeals(data.data);
      },
    }
  );

  const userReviews = useQuery(
    "userReviews",
    () =>
      userServices.commonGetService(
        `/fetchReviewsShopper/${localStorageData("_id")}`
      ),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        setReview(data.data);
      },
    }
  );

  return (
    <div>
      <Header />

      <ShopperButton />
      <Container maxWidth="lg">
        {/* <div className='row1 mt-4'>
          {ShopperDeals.isLoading === false ? (
            <Maincard cardData={deals} />
          ) : (
            <div className='row1 '>
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div> */}

        <div className="row1 mt-4">
          {userReviews.isLoading === false ? (
            <ReviewCard data={review} />
          ) : (
            <div className="row1 ">
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Shopper;
