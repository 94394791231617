import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './client/App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropdown-tree-select/dist/styles.css';
import 'react-slideshow-image/dist/styles.css';
import { ToastContainer } from 'react-toastify';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { composeWithDevTools } from 'redux-devtools-extension';

import { master } from './client/reducers/combineReducer';

import AuthVerifyComponent from './authverify.component';

import SimpleModal from './components/Model/SimpleModal';
// import Routes from './Admin/routes';
const store = createStore(master, composeWithDevTools(applyMiddleware(thunk)));
const queryClient = new QueryClient();
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <Router history={history}>
          <Switch>
            <Route path='/'>
              <App />

              <AuthVerifyComponent />
              <SimpleModal />
            </Route>
            {/* <Route path='/admin' component={Routes} /> */}

            {/* <Route exact path='/admin'>
              <Routes />
            </Route> */}
          </Switch>
        </Router>
        <ToastContainer />
      </React.StrictMode>
    </QueryClientProvider>
  </Provider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
