import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import history from "../history";
// import TotalCustomers from './Components/TotalCustomers';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useQuery } from "react-query";
import userServices from "../services/httpService/userAuth/userServices";
const columns = [
  {
    id: "counterId",
    label: "Order Id",
    minWidth: 170,
  },
  {
    id: "shop",
    label: "Shopper Name",
    minWidth: 170,
  },
  //// { id: 'productDetail', label: 'Product Name', minWidth: 170 },
  { id: "TotalPrice", label: "Total Cost Paid", minWidth: 170 },
  { id: "TotalPrice", label: "Service Charges", minWidth: 170 },
  { id: "TotalPrice", label: "Admin commission", minWidth: 170 },
  { id: "TotalPrice", label: "Pay Back", minWidth: 170 },
  { id: "shipmentdetails", label: "Tracking No", minWidth: 170 },
  {
    id: "shipmentdetails",
    label: "BSB",
    minWidth: 170,
  },
  { id: "shipmentdetails", label: "Account Name", minWidth: 170 },
  { id: "shipmentdetails", label: "Account Number", minWidth: 170 },
  { id: "shipmentdetails", label: "Contact Number", minWidth: 170 },

  { id: "shipmentdetails", label: "courierName", minWidth: 170 },
  { id: "shipmentdetails", label: "courierWeb", minWidth: 170 },
  { id: "orderStatus", label: "Order Status", minWidth: 170 },
  // { id: 'Payment complete', label: 'Action', minWidth: 170 },
];

///const rows = [createData("no", "name@gmail.com", "username")];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});
function Orders() {
  const [rows, setrows] = useState([]);
  // eslint-disable-next-line
  const { isLoading, error, data } = useQuery(
    "ordersData",
    () => userServices.commonGetService(`/fetchadminallorder`),
    {
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setrows(data.data);
        console.log(data.data);
      },
    }
  );

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [rows, setrows] = useState(orderList);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (i) => {
    history.push({
      pathname: "/orderdetail",

      state: { detail: rows[i], from: "admin" },
    });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          {/* <TotalCustomers /> */}
        </Grid>
      </Grid>

      <br />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                      onClick={() => handleClick(i)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "shop") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.fname}
                            </TableCell>
                          );
                        } else if (column.id === "Payment complete") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                /// onClick={() => handleAccept(i)}
                                style={{ background: "orange" }}
                              >
                                Complete
                              </Button>
                            </TableCell>
                          );
                        } else if (column.label === "Service Charges") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {1.99}
                            </TableCell>
                          );
                        } else if (column.label === "Admin commission") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {((5 / 100) * value).toFixed(2)}
                            </TableCell>
                          );
                        } else if (column.label === "Pay Back") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {(value - ((5 / 100) * value + 1.99)).toFixed(2)}
                            </TableCell>
                          );
                        } else if (column.label === "Total Cost Paid") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.toFixed(2)}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "Tracking No"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.trackingNo}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "BSB"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.BSB}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "Account Name"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.AccountName}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "Account Number"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.AccountNo}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "Contact Number"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.contactNo}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "courierName"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.courierName}
                            </TableCell>
                          );
                        } else if (
                          column.id === "shipmentdetails" &&
                          column.label === "courierWeb"
                        ) {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.courierWeb}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default Orders;
