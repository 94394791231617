import React from "react";
import { useFormik } from "formik";
import Container from "@material-ui/core/Container";
import userServices from "services/httpService/userAuth/userServices";
import { localStorageData } from "services/auth/localStorageData";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import imageCompression from "browser-image-compression";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import history from "./../../history";

import { useQuery } from "react-query";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

function Display() {
  const classes = useStyles();

  const [images, setImages] = React.useState([]);
  const [fetchOrders, setFetchOrders] = React.useState([]);
  const [filterorder, setFilterorder] = React.useState([]);

  const maxNumber = 1;
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 550,
    useWebWorker: true,
    fileType: "image/png",
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onChangeHandler = (e) => {
    // formik.setFieldValue('pics', e.target.files[0]);

    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImages(e.target.files[0]);
    }
  };

  const onChange = async (imageList, addUpdateIndex) => {
    // data for submit
    let myallfiles = [];

    for (let i = 0; i < imageList.length; i++) {
      let file = dataURLtoFile(imageList[i].data_url, imageList[i].file.name);

      let compressedFile = await imageCompression(file, options);

      myallfiles.push(compressedFile);
    }
    formik.setFieldValue("pics", myallfiles);

    setImages(imageList);
  };

  const orderNumber = (values) => {
    formik.setFieldValue("orderId", values.target.value);
    setFilterorder(
      fetchOrders.filter((item) => item.counterId == values.target.value)
    );
  };

  const formik = useFormik({
    initialValues: {
      orderId: "",
      productId: "",
      review: "",
      // pics: '',
    },
    validationSchema: Yup.object().shape({
      productId: Yup.string().required("Required"),
      review: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const filtercategory = filterorder[0].productDetail[0].products.filter(
        (item) => item.productId == values.productId
      );

      const formData = new FormData();

      formData.append("pics", images);
      formData.append("orderId", fetchOrders[0]._id);
      formData.append("productId", values.productId);
      formData.append("review", values.review);
      formData.append("category", filtercategory[0].data.category);

      console.log(values);
      mutate(formData);
    },
  });

  const { data } = useQuery(
    "userStatus",
    () =>
      userServices.commonGetService(
        `/fetchOrderReview/${localStorageData("_id")}`
      ),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log("---------------");
        // console.log(data.data[0].sellers[0].businessName);
        setFetchOrders(data.data);
      },
    }
  );

  const { isError, isLoading, isSuccess, mutate } = useMutation(
    (uploadDeals) => userServices.commonPostService("/postReview", uploadDeals),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Review successfully posted");

        history.push(`/shopper`);
      },
    }
  );

  return (
    <div>
      <Container maxWidth="sm">
        <form className=" relative mb-3 mt-20 " onSubmit={formik.handleSubmit}>
          <div className=" my-6 center-styl">
            <img
              id="output"
              className="h-32 w-32 rounded my-6"
              src="https://ui-avatars.com/api/?name=NA"
              alt="profile"
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
            </label>

            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              onChange={onChangeHandler}
              type="file"
            />
          </div>
          <div className="relative w-full mb-3 ">
            <select
              name="category"
              id="category"
              className="input-styl h-12"
              onChange={orderNumber}
            >
              <option value="" disabled selected>
                Order Number
              </option>
              {fetchOrders.map((s, i) => (
                <option value={s.counterId}>{s.counterId}</option>
              ))}
            </select>
          </div>
          {/* {fetchOrders.map((s, i) => ( */}
          {filterorder != "" ? (
            <div>
              <div className="text text-black my-4">
                Business Name: {filterorder[0].sellers[0].businessName}
              </div>
              <div className="relative w-full mb-3 ">
                <select
                  name="productId"
                  id="productId"
                  className="input-styl h-12"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.productId}
                >
                  <option value="" disabled selected>
                    Product name
                  </option>
                  {filterorder[0].productDetail[0].products.map((s, i) => (
                    <option value={s.data._id}>{s.data.productName}</option>
                  ))}
                </select>
                {formik.touched.productId && formik.errors.productId ? (
                  <div className="text-red-600 m">
                    {formik.errors.productId}
                  </div>
                ) : null}
              </div>
              <div className="">
                <textarea
                  type="text"
                  name="review"
                  id="review"
                  className="input-styl  px-2 py-2"
                  placeholder="Review"
                  rows="4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.review}
                ></textarea>
                {formik.touched.review && formik.errors.review ? (
                  <div className="text-red-600">{formik.errors.review}</div>
                ) : null}
              </div>

              {isLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                <button className="btn-styl mt-4" type="submit">
                  Review
                </button>
              )}
            </div>
          ) : null}

          {/* // ))} */}
        </form>
      </Container>
    </div>
  );
}

export default Display;
