import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
function Privacypolicy() {
  return (
    <div>
      <br />
      <br />
      <Container>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='center-styl'> Privacy Policy</div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              By using our Service, you acknowledge and agree that your Personal
              Information is collected and utilised exclusively within our
              website to enhance your experience. We want to emphasise that no
              third party has access to any user information, and we guarantee
              the 100% protection of your data.
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>
              Information Collection and Use
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              To optimise your experience, we may request personally
              identifiable information such as user names, locations, and
              pictures, which is retained on your device and utilised strictly
              in accordance with this privacy policy. The website may employ
              third-party services, but rest assured, your information remains
              within the confines of our platform.
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>Log Data</div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              In the event of errors on the website, we collect Log Data through
              third-party products, including device IP addresses, names,
              operating system versions, and usage statistics. This data is used
              solely for diagnostic purposes within the service.
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>
              Service Providers
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              No third-party companies have access to any user information. We
              guarantee that your Personal Information is strictly protected and
              used only for assigned tasks within our website.
            </p>
          </Grid>

          {/* 
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul className='text-black text font-medium'>
              <li>To facilitate our Service</li>
              <li>To provide the Service on our behalf</li>
              <li>To perform Service-related services</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>

            <p className='text-black text font-medium'>
              we want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
          </Grid> */}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>Security</div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              We guarantee the safety of your information and employ
              commercially acceptable means to protect your Personal
              Information. we strive to maintain the trust you place in us.
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>
              Children’s Privacy
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              Our services are not intended for individuals under the age of 13.
              If we inadvertently collect personal information from a child
              under 17, we promptly delete it upon discovery. Parents or
              guardians can contact us to take necessary actions.
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>
              Changes to This Privacy Policy
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              We may update our Privacy Policy periodically, and any changes
              will be communicated by posting the updated policy on this page.
              Please review this page periodically for the latest information.
            </p>

            <p className='text-black text font-medium'>
              This policy is effective as of 2024-02-06
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className='text-black text-lg mt-4 font-bold'>Contact US</div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className='text-black text font-medium'>
              For any questions or suggestions regarding our Privacy Policy,
              feel free to contact us
              <br /> ouiiwe@outlook.com
            </p>

            <p className='text-black text font-medium'>
              We appreciate your trust and guarantee the 100% protection of your
              information.
              {/* CEO/ Founder Patrick Luwemba */}
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Privacypolicy;
