import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import ListingColor from 'common/ListingColor';

function SellModel(props) {
  const [colors, setColors] = useState();
  const [qty, setQty] = useState();

  const [size, setSize] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (size == '') {
      alert('Enter size');
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    var arr = [];

    for (let i = 0; i < props.sizeData.length; i++) {
      arr.push({
        size: props.sizeData[i].size,
        qty: props.sizeData[i].qty,
        color: props.sizeData[i].color,
      });
    }

    arr.push({
      size: size,
      qty: qty,
      color: colors,
    });

    props.setSizeData(arr);
    console.log('new', arr);
    handleClose();
  };

  const handledDelete = (index) => {
    var arr = [];

    for (let i = 0; i < props.sizeData.length; i++) {
      if (i != index) {
        arr.push({
          size: props.sizeData[i].size,
          qty: props.sizeData[i].qty,
          color: props.sizeData[i].color,
        });
      }
    }
    props.setSizeData(arr);
  };
  var arrColor = [];
  for (var i = 0; i < ListingColor.length; i++) {
    arrColor.push({ label: ListingColor[i], value: ListingColor[i] });
  }

  console.log();

  useEffect(() => {}, []);

  return (
    <div>
      <div className='flex ml-3 my-3'>
        <input
          type='text'
          className='input-styl  px-2 py-2'
          onChange={(e) => setSize(e.target.value)}
          value={size}
          // onKeyUp={(event) => (event.keyCode === 32 ? addTagData(event) : null)}
          placeholder='Size'
        />
        <button
          className='btn-styl ml-6 cursor-pointer'
          onClick={handleClickOpen}
        >
          Add Colours
        </button>
      </div>

      {props.sizeData != '' ? (
        <table className='table-auto border-collapse border border-gray-600 my-6 p-4'>
          <thead>
            <tr>
              <td className='border border-gray-600 p-4'>Size </td>
              <td className='border border-gray-600 p-4'>Color</td>
              <td className='border border-gray-600 p-4'>Quantity</td>
              <td className='border border-gray-600 p-4'>Delete</td>
            </tr>
          </thead>
          <tbody>
            {props.sizeData.map((item, i) => (
              <tr className=' '>
                <td className='border border-gray-600 p-4'>{item.size}</td>
                <td className='border border-gray-600 p-4'>{item.color}</td>
                <td className='border border-gray-600 p-4'>{item.qty}</td>
                <td className='border border-gray-600 p-4'>
                  <button onClick={() => handledDelete(i)}>
                    <i className='fas fa-trash'></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{size}</DialogTitle>
        <DialogContent>
          <div className='mt-4'>
            <label for='colors'>Colors:</label>

            <div className=' flex content-center items-center justify-center'>
              {/* <select
                name='colors'
                id='colors'
                className='input-styl h-12'
                onChange={(e) => setColors(e.target.value)}
                value={colors}
              >
                <option value='' disabled selected>
                  Color
                </option>

                {arrColor.map((item) => (
                  <>
                    <option value={item.label}>{item.label}</option>
                  </>
                ))}
              </select> */}

              <Select
                name='colors'
                closeMenuOnSelect={false}
                onChange={(e) => setColors(e.value)}
                isSearchable={true}
                label='Select color'
                options={arrColor}
                className='basic-multi-select input-styl'
                classNamePrefix='select'
              />
            </div>
          </div>

          <div className='mt-4'>
            <label for='qty'>Quantity:</label>

            <input
              type='number'
              className='input-styl h-12 px-2 py-2 my-2'
              placeholder='Quantity'
              name='qty'
              id='qty'
              onChange={(e) => setQty(parseInt(e.target.value))}
              //// value={qty}
              //   onChange={formik.handleChange}
              //   onBlur={formik.handleBlur}
              //   value={formik.values.shopName}
            />
          </div>

          <button className='btn-styl ml-6 cursor-pointer' onClick={handleAdd}>
            Add
          </button>

          <div className=' fullwidth' />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleAdd}>Add</Button> */}
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SellModel;
