import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Category from '../../components/Data/index';
import { filterProductByDeal } from '../../globalFunctions/filterProductBycategory';
import { useDispatch, useSelector } from 'react-redux';
import { fetchdeals } from 'client/actions/userAction/dealsAction';

import Skelton from 'components/Card/Skelton';
function DealofDay() {
  const dispatch = useDispatch();
  const { error, isLoading, data } = useSelector((state) => state.deals);

  useEffect(() => {
    dispatch(fetchdeals());
  }, []);

  return (
    <div>
      <Container>
        {isLoading == false ? (
          <>
            <div className='mx-auto mt-10 items-center p-4'>
              <h2 className='center-styl mt-10'>Top Sellers</h2>
            </div>
            <Divider />

            <Grid container spacing={1}>
              {Category.map((s, i) => (
                <Grid item lg={3} md={4} sm={12} xs={12}>
                  {filterProductByDeal(s, data)}
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Skelton />
            <Skelton />
            <Skelton />
            <Skelton />
          </>
        )}
      </Container>
    </div>
  );
}

export default DealofDay;
