import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { s3BucketUrl } from "config/config";
import ErrorService from "services/formatError/ErrorService";
import history from "./../../history";
import CircularProgress from "@material-ui/core/CircularProgress";
import userServices from "services/httpService/userAuth/userServices";
import { useMutation } from "react-query";

import NoResults from "components/Notfound/NoResults";

function UserSearch() {
  const [userName, setuserName] = useState("");
  const [userData, setUserData] = useState([]);

  const [businessData, setbusinessData] = useState([]);

  const [address, setaddress] = useState("");

  const { isLoading, mutate } = useMutation(
    (updateprofile) =>
      userServices.commonPostService("/searusers", updateprofile),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log("search=>", data.data);
        setUserData(data.data.user);
        setbusinessData(data.data.business);
        // history.push({

        //   state: { detail: data.data },
        // });
      },
    }
  );

  const handleSearch = () => {
    if (userName == "") {
      toast.error("Search fields empty");
    } else {
      let data = {
        userName: userName,
      };
      //   console.log(data);
      mutate(data);
    }
  };

  return (
    <div className="flex mt-20 mb-96 ">
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={9} xs={9}>
            <div className="">
              <input
                className="	input-styl px-2 py-2 h-12 rounded-3xl"
                type="text"
                name="Search"
                id="search"
                placeholder="Search"
                onChange={(e) => {
                  setuserName(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <div className="">
              {isLoading ? (
                <>
                  <CircularProgress />
                </>
              ) : (
                <button
                  className="btn-styl h-10"
                  type="button"
                  onClick={handleSearch}
                >
                  Search
                </button>
              )}
            </div>
          </Grid>
        </Grid>

        {userData.map((s, i) => (
          <a
            className="flex flex-row gap-2 grid-cols-2  mt-6"
            onClick={() => {
              history.push(`/visitprofile/${s._id}`);
            }}
          >
            <img
              alt="profile picture"
              className="h-10 w-10 rounded-full"
              src={
                s.pic.includes("https") == true ? s.pic : s3BucketUrl + s.pic
              }
            />
            <div className="text-black font-semibold my-2">
              {s.fname} {s.lname}
            </div>
          </a>
        ))}

        {businessData.map((s, i) => (
          <a
            className="flex flex-row gap-2 grid-cols-2  mt-6"
            onClick={() => {
              history.push(`/visitprofile/${s.users[0]._id}`);
            }}
          >
            <img
              alt="profile picture"
              className="h-10 w-10 rounded-full"
              src={
                s.pic.includes("https") == true ? s.pic : s3BucketUrl + s.pic
              }
            />
            <div className="text-black font-semibold my-2">
              {s.businessName}
            </div>
          </a>
        ))}
      </Container>
    </div>
  );
}

export default UserSearch;
