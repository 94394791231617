import React, { useState } from 'react';

import { localStorageData } from 'services/auth/localStorageData';

import userServices from 'services/httpService/userAuth/userServices';
import { useQuery } from 'react-query';
import Skelton from 'components/Card/Skelton';

import ReviewCard from 'components/Card/ReviewCard';

function ReviewSmallBusiness() {
  const [review, setReview] = useState([]);
  const userReviews = useQuery(
    'userReviews',
    () =>
      userServices.commonGetService(
        `/fetchReviewsBusiness/${localStorageData('_id')}`
      ),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        setReview(data.data);
        console.log('---------------------------------');

        console.log(data.data);
      },
    }
  );

  return (
    <div className='min-h-screen'>
      <div className='row1'>
        {userReviews.isLoading === false ? (
          <ReviewCard data={review} />
        ) : (
          <div className='row1 '>
            <Skelton />
            <Skelton />
            <Skelton />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewSmallBusiness;
