import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import userServices from 'services/httpService/userAuth/userServices';
import Maincard from 'components/Card/Maincard';

import Skelton from 'components/Card/Skelton';

import NoResults from 'components/Notfound/NoResults';

import { useQuery } from 'react-query';

import { useParams } from 'react-router-dom';
function Categorypage() {
  const params = useParams();

  const [productData, setproductData] = useState([]);

  const { isLoading, data } = useQuery(
    'dealscategory',
    () => userServices.commonGetService(`/getproductbycategory/${params.name}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setproductData(data.data);
      },
    }
  );

  console.log(productData);
  ///console.log(productData);
  return (
    <div className='mt-20'>
      {isLoading == false ? (
        <>
          {productData != '' ? (
            <Container maxWidth='lg'>
              <div className='center-styl'>{params.name}</div>
              <div className='row1'>
                <Maincard cardData={productData} swipe={false} />
              </div>
            </Container>
          ) : (
            <NoResults name='No Deal Available' />
          )}
        </>
      ) : (
        <>
          <Skelton />
          <Skelton />
          <Skelton />
          <Skelton />
        </>
      )}
    </div>
  );
}

export default Categorypage;
