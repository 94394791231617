import React, { useState } from 'react';
import Container from '@material-ui/core/Container';

import {
  localStorageData,
  updatelocalData,
} from '../../services/auth/localStorageData';
import { useMutation } from 'react-query';
import userServices from '../../services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import history from './../../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorService from '../../services/formatError/ErrorService';
import Address from '../../components/Address';

function DetailsEdit() {
  const [address, setaddress] = useState(localStorageData('address'));

  const [concatNo, setconcatNo] = useState(localStorageData('contactNo'));

  const [fname, setfname] = useState(localStorageData('fname'));
  const [lname, setlname] = useState(localStorageData('lname'));

  const {
    isError,

    isLoading,

    isSuccess,
    mutate,
  } = useMutation(
    (updateprofile) =>
      userServices.commonPostService('/updateuserinfo', updateprofile),
    {
      onError: (error) => {
        ///// seterrorMsg(ErrorService.uniformError(error));
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log(data);

        updatelocalData(data.data);

        toast.success('Profile Updated');

        /// history.push('/');
      },
    }
  );

  const savedetails = async () => {
    let data = {
      id: localStorageData('_id'),
      fname: fname,
      lname: lname,
      address: address,
      contactNo: concatNo,
    };

    mutate(data);
  };

  return (
    <div>
      <Container maxWidth='sm'>
        <div className='center-styl mt-20'>My Details</div>

        <div className=' flex content-center my-4 items-center justify-center'>
          <label className='w-40'>First name:</label>
          <input
            type='text'
            name='userName'
            id='userName'
            value={fname}
            onChange={(e) => setfname(e.target.value)}
            className='input-styl mx-2 h-12 px-2 py-2 w-20'
            placeholder='First name'
          />
        </div>

        <div className=' flex content-center my-4 items-center justify-center'>
          <label className='w-40'>Last name:</label>
          <input
            type='text'
            name='userName'
            id='userName'
            value={lname}
            onChange={(e) => setlname(e.target.value)}
            className='input-styl mx-2 h-12 px-2 py-2 w-20'
            placeholder='Last name'
          />
        </div>

        <div className=' flex content-center my-4 items-center justify-center'>
          <label className='w-40'>User name:</label>
          <input
            type='text'
            name='userName'
            id='userName'
            value={localStorageData('username')}
            disabled
            className='input-styl mx-2 h-12 px-2 py-2 w-20'
            placeholder='User name'
          />
        </div>

        <div className=' flex  content-center my-4 items-center justify-center'>
          <label className='w-40'>Email:</label>
          <input
            type='email'
            name='email'
            id='email'
            disabled
            value={localStorageData('email')}
            className='input-styl mx-2 h-12 px-2 py-2'
            placeholder='Email'
          />
        </div>

        <div className=' flex  content-center my-4 items-center justify-center'>
          <label className='w-40'>Contact No:</label>
          <input
            type='tel'
            max='1'
            name='contactNo'
            id='contactNo'
            value={concatNo}
            onChange={(e) => setconcatNo(e.target.value)}
            className='input-styl mx-2 h-12 px-2 py-2'
            placeholder='Contact No'
          />
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>Address:</label>

          <Address
            label='adasd'
            setaddress={setaddress}
            address={address}
            // onChange={(e) => setaddress(e.target.value)}
          />
        </div>
        {address}
        <div className='flex mt-10 justify-end content-end'>
          {isLoading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <button
              className='btn-styl h-10'
              type='button'
              onClick={savedetails}
            >
              Save
            </button>
          )}
        </div>
      </Container>
    </div>
  );
}

export default DetailsEdit;
