import React from 'react';

import Header from 'components/Header';
import Container from '@material-ui/core/Container';
import Maincard from 'components/Card/Maincard';
import { useLocation } from 'react-router-dom';
import NoResults from 'components/Notfound/NoResults';

function SearchedResult() {
  // const filterProducts = async (filterTag) => {
  //   return data.filter((values) => values._id == filterTag);
  // };

  const location = useLocation();

  console.log(location.state.detail);
  return (
    <div>
      <Header />
      <Container>
        {location.state.detail != '' ? (
          <div className='row1'>
            <Maincard
              cardData={location.state.detail}
              seemore={false}
              swipe={false}
            />
          </div>
        ) : (
          <NoResults name='No Deal Available' />
        )}
      </Container>
    </div>
  );
}

export default SearchedResult;
