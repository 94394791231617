import React, { useState } from 'react';
import Container from '@material-ui/core/Container';

import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { localStorageData } from 'services/auth/localStorageData';
import { useMutation } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import history from './../../history';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

function ShipmentDetails() {
  const dispatch = useDispatch();

  const location = useLocation();

  const { isLoading, mutate } = useMutation(
    (confirmOrder) =>
      userServices.commonPostService('/confirmOrder', confirmOrder),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        toast.success('Order Has been Confirmed');

        history.push('/ordersmallbusiness');
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      //   orderNo: '',
      AccountNo: '',
      AccountName: '',

      contactNo: localStorageData('contactNo'),
      trackingNo: '',
      BSB: '',
      courierName: '',
      courierWeb: '',
    },
    validationSchema: Yup.object().shape({
      ///// orderNo: Yup.string().required('Required'),
      AccountNo: Yup.string().required('Required'),
      AccountName: Yup.string().required('Required'),

      contactNo: Yup.string().required('Required'),
      trackingNo: Yup.string().required('Required'),
      BSB: Yup.string().required('Required'),
      courierName: Yup.string().required('Required'),
      courierWeb: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      ///alert(values);

      values.orderId = location.state.orderId;

      mutate(values);
    },
  });

  return (
    <div>
      <Container maxWidth='sm'>
        <form
          className=' relative  mt-4 mb-3 mt-20'
          onSubmit={formik.handleSubmit}
        >
          <div className='flex grid content-center items-center justify-center my-6'>
            <div className='text-lg subpixel-antialiased font-semibold tracking-wide text-black'>
              shipment details
            </div>
          </div>

          <div className='flex grid content-center items-center justify-center my-6'>
            <div className='text-lg subpixel-antialiased font-semibold tracking-wide text-black'>
              Order Number : {location.state.counterId}
            </div>
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>AccountName:</label>
            <input
              type='text'
              name='AccountName'
              id='AccountName'
              value={formik.values.AccountName}
              onChange={(e) =>
                formik.setFieldValue('AccountName', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='AccountName'
            />

            {formik.touched.AccountName && formik.errors.AccountName ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.AccountName}
              </div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>AccountNo:</label>
            <input
              type='text'
              name='AccountNo'
              id='AccountNo'
              value={formik.values.AccountNo}
              onChange={(e) =>
                formik.setFieldValue('AccountNo', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='AccountNo'
            />
            {formik.touched.AccountNo && formik.errors.AccountNo ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.AccountNo}
              </div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>BSB:</label>
            <input
              type='text'
              name='BSB'
              id='BSB'
              value={formik.values.BSB}
              onChange={(e) => formik.setFieldValue('BSB', e.target.value)}
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='BSB'
            />
            {formik.touched.BSB && formik.errors.BSB ? (
              <div className='text-red-600 text-xs'>{formik.errors.BSB}</div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>contactNo:</label>
            <input
              type='text'
              name='contactNo'
              id='contactNo'
              value={localStorageData('contactNo')}
              onChange={(e) =>
                formik.setFieldValue('contactNo', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='contactNo'
            />
            {formik.touched.contactNo && formik.errors.contactNo ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.contactNo}
              </div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>trackingNo:</label>
            <input
              type='text'
              name='trackingNo'
              id='trackingNo'
              value={formik.values.trackingNo}
              onChange={(e) =>
                formik.setFieldValue('trackingNo', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='trackingNo'
            />
            {formik.touched.trackingNo && formik.errors.trackingNo ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.trackingNo}
              </div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>courier Website:</label>
            <input
              type='text'
              name='courierWeb'
              id='courierWeb'
              value={formik.values.courierWeb}
              onChange={(e) =>
                formik.setFieldValue('courierWeb', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='courierWeb'
            />
            {formik.touched.courierWeb && formik.errors.courierWeb ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.courierWeb}
              </div>
            ) : null}
          </div>

          <div className=' flex content-center my-4 items-center justify-center'>
            <label className='w-40'>courier Name:</label>
            <input
              type='text'
              name='courierName'
              id='courierName'
              value={formik.values.courierName}
              onChange={(e) =>
                formik.setFieldValue('courierName', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20'
              placeholder='courierName'
            />
            {formik.touched.courierName && formik.errors.courierName ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.courierName}
              </div>
            ) : null}
          </div>

          <div className='flex mt-10 justify-end content-end'>
            {isLoading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <button className='btn-styl h-10' type='submit'>
                Submit
              </button>
            )}
          </div>
        </form>
      </Container>
    </div>
  );
}

export default ShipmentDetails;
