import React, { useState, useEffect } from 'react';
import history from './../../history';
import Container from '@material-ui/core/Container';
import userServices from 'services/httpService/userAuth/userServices';
import Maincard from 'components/Card/Maincard';
import Skelton from 'components/Card/Skelton';
import Grid from '@material-ui/core/Grid';
import { useQuery } from 'react-query';
import { s3BucketUrl } from 'config/config';
import moment from 'moment';
import { useParams } from 'react-router-dom';

let targetTime;

function ProductDetails() {
  const params = useParams();

  const [productData, setproductData] = useState([]);

  const { isLoading, data } = useQuery(
    'userStatus',
    () => userServices.commonGetService(`/getproductdetails/${params.id}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        /////seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        data.data.forEach((item) => (item.users = item.user));
        setproductData(data.data);
        console.log('-------------------------');
        console.log(data.data);
        console.log('-------------------------');

        targetTime = new Date(data.data[0].expireDate).getTime();
      },
    }
  );

  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetTime - currentTime;

  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  return (
    <div className='mt-20'>
      <Container maxWidth='sm'>
        {productData != '' ? (
          <>
            <a
              className='flex flex-row gap-1  my-2'
              onClick={() => {
                history.push(`/visitprofile/${productData[0].users._id}`);
              }}
            >
              <img
                alt='...'
                className='h-10 w-10 rounded-full'
                src={
                  productData[0].users.pic.includes('https') == true
                    ? productData[0].users.pic
                    : s3BucketUrl + productData[0].users.pic
                }
              />
              <div className='my-2 mx-2 text-black font-semibold'>
                {productData[0].seller.businessName}
              </div>
            </a>
            <div>
              {/* {moment(productData[0].expireDate).format(
                'DD MM YYYY, h:mm:ss a'
              )} */}

              <span>{days} Day/s remaining</span>
            </div>

            <Grid container spacing={5}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Maincard isShow={true} cardData={productData} swipe={true} />
              </Grid>
              <Grid item lg={6} md={6} sm={10} xs={10}>
                <div className='border border-light-blue-500 border-opacity-100  p-4'>
                  description:{' '}
                  {productData != '' ? productData[0].caption : null}
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <Skelton />
        )}
      </Container>
    </div>
  );
}

export default ProductDetails;
