import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import { s3BucketUrl } from "config/config";
import { localStorageData } from "services/auth/localStorageData";
import { useDispatch, useSelector } from "react-redux";
import history from "./../../history";
import { DeleteProductCart } from "client/actions/CartAction/CartActions";
import { useMutation, useQuery } from "react-query";

import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import {
  IncerementProductCart,
  DecerementProductCart,
} from "client/actions/CartAction/CartActions";
import { serviceCharges } from "config/config";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import NoResults from "components/Notfound/NoResults";
function Cart() {
  const dispatch = useDispatch();
  const [couponValue, setCouponValue] = useState(0);

  const [isDiscount, setisDiscount] = useState("");

  const [finalDiscountApply, setfinalDiscountApply] = useState(false);

  const { userCart, userCartLength } = useSelector((state) => state.Cart);

  function deleteProduct(shopNumber, ProductNumber) {
    dispatch(DeleteProductCart(shopNumber, ProductNumber));
  }

  function increment_quantity(shopNumber, ProductNumber) {
    dispatch(IncerementProductCart(shopNumber, ProductNumber));
  }

  function decrement_quantity(shopNumber, ProductNumber) {
    dispatch(DecerementProductCart(shopNumber, ProductNumber));
  }

  const CheckOut = async (shopNumber, shopId) => {
    if (localStorageData("_id")) {
      //// toast.success('Login Required');

      ////history.push("/payment/" + shopNumber);

      history.push({
        pathname: "/payment/" + shopNumber,

        state: {
          detail:
            finalDiscountApply == true && shopId == isDiscount ? true : false,
        },
      });
    } else {
      toast.error("Login Required");
      history.push("/signin");
    }
  };

  const mutateDiscount = useMutation(
    (userDiscount) =>
      userServices.commonPostService("/userDiscount", userDiscount),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setisDiscount(data.data.data);

        setfinalDiscountApply(true);

        toast.success("Discount Appllied");
      },
    }
  );

  const applyDiscount = async (shopID) => {
    if (localStorageData("_id")) {
      let IdFromLocal = localStorage.getItem("checkIfAlreadyTookDiscount");

      console.log(shopID);
      let shopsIds = [
        "66249ff0665aca16a6fa3e2a",
        "654b483f1c2cb2b289a47958",
        "6651e7c3e1e6091e33fab7b6",
        "663f57a4e1e6091e33faafd7",
        "6510c9fd8e456588676b3ca8",
        "65d83f9fd9f6d4daca6bc748",
        "666a6dc4997589b4c93ec189",
        "666a6dc4997589b4c93ec189",
        "66ece6c6d72bda0970c6613d",
        "66236641665aca16a6fa3db3",
        "66ac4a4786d7cc0ae7f3703a",
        "669f12e086d7cc0ae7f36411",
      ];

      if (
        shopsIds.includes(shopID) &&
        couponValue == "ouiiwe96" &&
        IdFromLocal != shopID
      ) {
        setisDiscount(shopID);
        setfinalDiscountApply(true);

        toast.success("Discount Appllied");
      } else {
        toast.error("No Coupon exist");
      }
    }

    // if (localStorageData("_id")) {
    //   mutateDiscount.mutate({
    //     shopId: shopID,
    //     couponValue: couponValue,
    //   });
    // }
  };
  console.log(userCart);

  console.log();
  console.log(isDiscount);

  function getTotalPrice(item) {
    let price;

    price =
      item.products.reduce(
        (sum, { quantity, nowPrice, postageFee }) =>
          sum + (quantity * nowPrice + postageFee),
        0
      ) + serviceCharges;

    if (isDiscount == item.shopId) {
      price = price - 20;
    }
    if (price < 0) {
      price = 0;
    }

    return price.toFixed(1);
  }

  return (
    <div>
      {userCart.length == 0 ? (
        <>
          <NoResults name="Your Cart is Empty" />
        </>
      ) : (
        userCart.map((item, i) => (
          <>
            <Container maxWidth="sm">
              <div className="relative flex flex-col mt-20 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-lightBlue-900 text-black my-10 grid grid-cols-1 divide-y divide-blueGray-800">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-lg ">
                        {item.shopName}
                      </h3>
                    </div>
                  </div>
                </div>
                {item.products.map((product, j) => (
                  <>
                    <div className="block w-full grid grid-cols-1 divide-y divide-blueGray-800">
                      <div className=" mx-4 my-4">
                        <div className="flex ">
                          <img
                            src={s3BucketUrl + product.data.pics[0]}
                            className="h-12 w-12 bg-white rounded-full border"
                            alt="..."
                          ></img>
                          <div className="ml-3 mt-2 text-base font-bold text-blueGray-600 ">
                            {product.data.productName}
                          </div>
                          <div className="items-right ml-10 float-right ">
                            <IconButton aria-label="delete" size="small">
                              <DeleteIcon
                                onClick={() => {
                                  deleteProduct(i, j);
                                }}
                                fontSize="small"
                              />
                            </IconButton>
                            &nbsp;&nbsp;
                          </div>
                        </div>

                        <div className="my-4 justify-items-center items-center justify-center">
                          <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={5} xs={5}>
                              {item.type != "services" ? (
                                <ButtonGroup
                                  size="small"
                                  aria-label="small outlined button group"
                                >
                                  <Button
                                    onClick={() => increment_quantity(i, j)}
                                  >
                                    +
                                  </Button>
                                  <Button>{product.quantity}</Button>
                                  <Button
                                    onClick={() => decrement_quantity(i, j)}
                                  >
                                    -
                                  </Button>
                                </ButtonGroup>
                              ) : null}
                            </Grid>
                            <Grid item lg={4} md={4} sm={3} xs={3}>
                              <div>{product.size}</div>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <div>{product.color}</div>
                            </Grid>

                            {item.type != "services" ? (
                              <Grid item lg={6} md={6} sm={6} xs={6}>
                                <div>Postage fee:$ {product.postageFee}</div>
                              </Grid>
                            ) : (
                              moment(item.startTime).format(
                                "MM/DD/YYYY h:mm A "
                              ) +
                              "- " +
                              moment(item.endTime).format(" h:mm A ")
                            )}

                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <div className="text-base float-right">
                                Cost: ${product.quantity * product.nowPrice}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex float-left">
                    <h3 className="font-semibold text-base ">
                      Cost: ${" "}
                      {item.products.reduce(
                        (sum, { quantity, nowPrice }) =>
                          sum + quantity * nowPrice,
                        0
                      )}
                    </h3>
                  </div>

                  <div className="flex float-right">
                    <h3 className="font-semibold text-base ">
                      Service Charges: ${serviceCharges}
                    </h3>
                  </div>
                </div>

                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex float-left">
                    <h3 className="font-semibold text-base ">
                      Total Postage Fee: ${" "}
                      {item.products.reduce(
                        (sum, { postageFee }) => sum + postageFee,
                        0
                      )}
                    </h3>
                  </div>
                </div>
                <div className="mx-10 py-2 flex">
                  <div className="border-2 border-gray-600  flex">
                    <input
                      className="placeholder-gray-600 px-2 mx-4 text-gray-600 bg-white rounded-md text-sm  focus:outline-none  w-full h-10 ease-linear transition-all duration-150 "
                      placeholder="Discount code"
                      /// type="number"
                      ///// value={couponValue}
                      onChange={(e) => setCouponValue(e.target.value)}
                    />
                    <button
                      className="text-white bg-gray-600 px-4"
                      type="submit"
                      onClick={() => applyDiscount(item.shopId)}
                    >
                      Apply
                    </button>
                  </div>
                  <br />
                </div>

                {isDiscount == item.shopId ? (
                  <h5 className="text-sm text-center">you saved 20$</h5>
                ) : null}

                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex float-left">
                    <h3 className="font-semibold text-base ">
                      Total Coast: $ {getTotalPrice(item)}
                    </h3>
                  </div>

                  <div className="flex float-right">
                    <button
                      className="btn-styl"
                      type="submit"
                      onClick={() => CheckOut(i, item.shopId)}
                    >
                      Check out
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </>
        ))
      )}
    </div>
  );
}

export default Cart;
