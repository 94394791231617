import React, { useState } from "react";
import ProfileHeader from "components/ProfileHeader";
import Container from "@material-ui/core/Container";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import userServices from "services/httpService/userAuth/userServices";

import { s3BucketUrl } from "config/config";
import Skelton from "components/Card/Skelton";
import ReviewCard from "components/Card/ReviewCard";

function ShopperView() {
  const params = useParams();
  const [deals, setdeals] = useState([]);
  const [review, setReview] = useState([]);

  const [nameofuploder, setnameofuploder] = useState("");
  const [picuploder, setpicofuploder] = useState("");
  const [sellerPoints, setSellerPoints] = useState("");
  const [story, setStory] = useState("");

  // setnameofuploder(data.data[0].username);
  // setdeals(data.data[0].deals);
  // setpicofuploder(data.data[0].pic);
  // setStory(data.data[0].sellers.story);

  const ShopperDeals = useQuery(
    "userStatus",
    () => userServices.commonGetService(`/fetchbyvistorid/${params.id}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        console.log("--------------------s", data.data);
        setSellerPoints(data.data[0].sellerPoints);
      },
    }
  );

  const userReviews = useQuery(
    "userReviews",
    () => userServices.commonGetService(`/fetchReviewsShopper/${params.id}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        console.log("--------------------rev", data.data);

        if (data.data != "") {
          setpicofuploder(data.data[0].customer.pic);
          setnameofuploder(
            data.data[0].customer.fname + " " + data.data[0].customer.lname
          );
        }
        setReview(data.data);
      },
    }
  );

  return (
    <div>
      <ProfileHeader
        name={nameofuploder}
        vistorid={params.id}
        img={s3BucketUrl + picuploder}
        upload={false}
        search={false}
        follow={true}
        sellerPoints={sellerPoints}
      />
      <br />
      <Container maxWidth="lg">
        <div className="row1">
          {userReviews.isLoading === false ? (
            <ReviewCard data={review} />
          ) : (
            <div className="row1 ">
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ShopperView;
