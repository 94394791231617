import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { set } from 'react-ga';
import moment from 'moment-timezone';

function DateModel({ availability, setAvailability }) {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState('');

  const [inputFields, setInputFields] = useState([
    {
      startTime: '',
      endTime: '',
    },
  ]);

  const addInputField = () => {
    ////console.log(inputFields);
    setInputFields([
      ...inputFields,
      {
        startTime: '',
        endTime: '',
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleClickOpen = () => {
    if (date == '') {
      alert('Enter date');
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    ///// console.log(availability);

    var arr = [];

    for (let i = 0; i < availability.length; i++) {
      /// console.log("timing", availability[i].timing);

      arr.push({
        date: availability[i].date,
        timing: availability[i].timing,
      });
    }
    /////////////////for,at inut fild

    let newinputFields = [];
    for (let i = 0; i < inputFields.length; i++) {
      newinputFields.push({
        startTime: moment(date + ' ' + inputFields[i].startTime).format(
          'MM/DD/YYYY h:mm A '
        ),
        endTime: moment(date + ' ' + inputFields[i].endTime).format(
          'MM/DD/YYYY h:mm A '
        ),
      });
    }

    arr.push({
      date: date,
      timing: newinputFields,
    });

    setAvailability(arr);

    setInputFields([
      {
        startTime: '',
        endTime: '',
      },
    ]);

    setDate('');

    // var arr = availability;

    // // for (let i = 0; i < availability.length; i++) {
    // //   arr.push({
    // //     date: availability[i].date,
    // //     time: availability[i].inputFields,
    // //   });
    // // }
    // /////console.log('first', arr);

    // arr.push({
    //   date: date,
    //   time: inputFields,
    // });
    // console.log('last', arr);
    // setAvailability(arr);

    setOpen(false);
  };

  const handledDelete = () => {};

  return (
    <>
      <Grid item lg={10} xs={8}>
        <label for='qty'>Date:</label>
        <input
          type='date'
          className='input-styl h-12 w-full px-2 py-2 my-2'
          placeholder='date'
          name='date'
          id='date'
          onChange={(e) => setDate(e.target.value)}
          value={date}
        />
      </Grid>

      <Grid item lg={3} xs={3}>
        <button
          className='btn-styl  mt-8 cursor-pointer'
          onClick={handleClickOpen}
        >
          Add Date
        </button>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{date}</DialogTitle>
        <DialogContent>
          {inputFields.map((data, index) => {
            const { startTime, endTime } = data;
            return (
              <div className='' key={index}>
                <div>
                  Please note, cost might vary according to selected time
                  slots/duration of service.{' '}
                </div>

                <Grid container spacing={3}>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <div className='mt-4'>
                      <label for='startTime'>Start Time:</label>
                      <input
                        type='time'
                        className='input-styl h-12 px-2 py-2 my-2'
                        placeholder='Start Time'
                        name='startTime'
                        id='startTime'
                        onChange={(evnt) => handleChange(index, evnt)}
                        value={startTime}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <div className='mt-4'>
                      <label for='endTime'>End Time:</label>
                      <input
                        type='time'
                        className='input-styl h-12 px-2 py-2 my-2'
                        placeholder='End Time'
                        name='endTime'
                        id='endTime'
                        onChange={(evnt) => handleChange(index, evnt)}
                        value={endTime}
                      />
                    </div>
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    {inputFields.length !== 1 ? (
                      <div className='mt-14'>
                        <button className='' onClick={removeInputFields}>
                          <i className='fas fa-trash'></i>
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}

          <button
            className='btn-styl ml-6 cursor-pointer'
            onClick={addInputField}
          >
            Add
          </button>

          <div className=' fullwidth' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DateModel;
