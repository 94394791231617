import React, { useState } from 'react';
import Container from '@material-ui/core/Container';

import * as Yup from 'yup';

import { localStorageData } from 'services/auth/localStorageData';
import { useMutation } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import history from '../../../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorService from 'services/formatError/ErrorService';
import { useFormik } from 'formik';

function ApproveBusiness() {
  // const [isLoading, setisLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);

  const { mutate } = useMutation(
    (updateprofile) =>
      userServices.commonPostService('/sellerRequest', updateprofile),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        // setisLoading(false);
        toast.success(
          'Request Has been Sent. You will be Get Email when Verify'
        );
        // console.log(data.data);
        history.push('/');
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      businessName: '',
      typeOfbusiness: '',
      story: '',
      aboutBusiness: '',
      socialMedia: '',
      reason: '',
      website: '',
    },
    validationSchema: Yup.object().shape({
      businessName: Yup.string().required('Required'),
      typeOfbusiness: Yup.string().required('Required'),
      aboutBusiness: Yup.string().required('Required'),
      story: Yup.string().max(600, 'No more than 600 characters'),
      reason: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      // setisLoading(true);
      values.userId = localStorageData('_id');
      mutate(values);
      console.log(values);
    },
  });
  return (
    <Container maxWidth='sm'>
      <form
        className=' relative  mt-4 mb-3 mt-20'
        onSubmit={formik.handleSubmit}
      >
        <div className='center-styl'>Request to become a Small Business</div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>Businessname:</label>
          <input
            type='text'
            name='businessName'
            id='businessName'
            value={formik.values.businessName}
            onChange={(e) =>
              formik.setFieldValue('businessName', e.target.value)
            }
            className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-4'
            placeholder='BusinessName'
          />

          {formik.touched.businessName && formik.errors.businessName ? (
            <div className='text-red-600 text-xs'>
              {formik.errors.businessName}
            </div>
          ) : null}
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>Type of Business </label>
          <input
            type='text'
            name='typeOfbusiness'
            id='typeOfbusiness'
            value={formik.values.typeOfbusiness}
            onChange={(e) =>
              formik.setFieldValue('typeOfbusiness', e.target.value)
            }
            className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-4'
            placeholder='typeOfbusiness'
          />

          {formik.touched.typeOfbusiness && formik.errors.typeOfbusiness ? (
            <div className='text-red-600 text-xs'>
              {formik.errors.typeOfbusiness}
            </div>
          ) : null}
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>Website :</label>
          <input
            type='text'
            name='website'
            id='website'
            value={formik.values.website}
            onChange={(e) => formik.setFieldValue('website', e.target.value)}
            className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-4'
            placeholder='website'
          />
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>socialMedia :</label>
          <input
            type='text'
            name='socialMedia'
            id='socialMedia'
            value={formik.values.socialMedia}
            onChange={(e) =>
              formik.setFieldValue('socialMedia', e.target.value)
            }
            className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-4'
            placeholder='socialMedia'
          />
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'> About Business :</label>
          <textarea
            type='text'
            rows='4'
            cols='50'
            name='aboutBusiness'
            id='aboutBusiness'
            value={formik.values.aboutBusiness}
            onChange={(e) =>
              formik.setFieldValue('aboutBusiness', e.target.value)
            }
            className='input-styl mx-2 px-2 py-2 w-20 mt-4'
            placeholder='about Business'
          ></textarea>
          {formik.touched.aboutBusiness && formik.errors.aboutBusiness ? (
            <div className='text-red-600 text-xs'>
              {formik.errors.aboutBusiness}
            </div>
          ) : null}
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40'>Why ouiiwe</label>
          <textarea
            type='text'
            rows='4'
            cols='50'
            name='reason'
            id='reason'
            value={formik.values.reason}
            onChange={(e) => formik.setFieldValue('reason', e.target.value)}
            className='input-styl mx-2 px-2 py-2 w-20 mt-4'
            placeholder='Add Reason'
          ></textarea>
          {formik.touched.reason && formik.errors.reason ? (
            <div className='text-red-600 text-xs'>{formik.errors.reason}</div>
          ) : null}
        </div>

        <div className='  content-center my-4 items-center justify-center'>
          <label className='w-40 my-6'>Tell Your Story</label>
          <textarea
            type='text'
            rows='6'
            cols='50'
            name='story'
            id='story'
            value={formik.values.story}
            onChange={(e) => {
              formik.setFieldValue('story', e.target.value);
              setCharacterCount(e.target.value.length);
            }}
            className='input-styl mx-2 px-2 py-2 w-20 mt-4'
            placeholder='*What is the purpose of your business?
            *Who does your business represent?
            *who is your target audience and how do your products
            meet their values and believes?
            *why should your target audience buy from you?'
          ></textarea>
          <p className='float-right'>{characterCount} words</p>
          {formik.touched.story && formik.errors.story ? (
            <div className='text-red-600 text-xs'>{formik.errors.story}</div>
          ) : null}
        </div>

        <div className='center-styl mt-4'>
          Our team will review your Business application and get back to
          you within 24/hrs
        </div>

        <div className='flex mt-10 justify-end content-end'>
          {mutate.isLoading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <button
              className='btn-styl h-10'
              type='submit'
              //  onClick={savedetails}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </Container>
  );
}

export default ApproveBusiness;
