import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import animationData from '../../assets/json/nodata.json';
import Lottie from 'react-lottie';
function NoResults(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex grid content-center items-center my-8 justify-center  mx-4	'>
          {/* <img
            src='https://i.ibb.co/rGxWg0n/noresult.png'
            className='h-40 w-40 '
          /> */}

          <Lottie options={defaultOptions} height={150} width={300} />
          <Typography
            variant='h4'
            style={{
              marginTop: 20,
              //   marginLeft: 80,
            }}
          >
            {props.name}
          </Typography>
        </div>
      </Container>
    </div>
  );
}

export default NoResults;
