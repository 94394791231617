import React from 'react';
import Container from '@material-ui/core/Container';
function ContactAdmin() {
  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex grid content-center items-center justify-center my-6'>
          <div className='text-lg subpixel-antialiased font-semibold tracking-wide text-black'>
            UIN: 077668
          </div>

          <div className='flex  gap-4 mt-4'>
            <div className=' flex content-center items-center justify-center'>
              <input
                type='text'
                name='orderNo'
                id='orderNo'
                className='input-styl h-12 px-2 py-2'
                placeholder='Order No'
              />
            </div>
            <div className=' flex content-center items-center justify-center'>
              <input
                type='text'
                name='trackingNo'
                id='trackingNo'
                className='input-styl h-12 px-2 py-2'
                placeholder='Tracking No'
              />
            </div>
          </div>

          <div className='text subpixel-antialiased ml-6 my-4 font-semibold underline	tracking-wide text-gray-600'>
            Bank Details
          </div>
        </div>
        <div className=' flex content-center my-4 items-center justify-center'>
          <label className='w-40'>BSB:</label>
          <input
            type='text'
            name='BSB'
            id='BSB'
            className='input-styl mx-2 h-12 px-2 py-2 w-20'
            placeholder='BSB'
          />
        </div>

        <div className=' flex  content-center my-4 items-center justify-center'>
          <label className='w-40'>Account No:</label>
          <input
            type='text'
            name='accountNo'
            id='accountNo:'
            className='input-styl mx-2 h-12 px-2 py-2'
            placeholder='Account No'
          />
        </div>

        <div className=' flex  content-center my-4 items-center justify-center'>
          <label className='w-40'>Account Name:</label>
          <input
            type='text'
            name='accountName'
            id='accountName:'
            className='input-styl mx-2 h-12 px-2 py-2'
            placeholder='Account Name'
          />
        </div>

        <div className=' flex  content-center my-4 items-center justify-center'>
          <label className='w-40'>Contact No:</label>
          <input
            type='number'
            max='1'
            name='contactNo'
            id='contactNo'
            className='input-styl mx-2 h-12 px-2 py-2'
            placeholder='Contact No'
          />
        </div>

        <div className='flex mt-10 justify-end content-end'>
          <button
            className='btn-styl h-10'
            type='button'
            // onClick={() => toast.warning('search commin soon')}
          >
            Send
          </button>
        </div>
      </Container>
    </div>
  );
}

export default ContactAdmin;
