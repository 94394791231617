import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Category from "components/Data/index";
import { makeStyles } from "@material-ui/core/styles";

import { Carousel } from "react-responsive-carousel";
// import Select from '@material-ui/core/Select';
import { toast } from "react-toastify";
import userServices from "services/httpService/userAuth/userServices";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import Alert from "@material-ui/lab/Alert";
import { FormikConsumer, useFormik } from "formik";
import { localStorageData } from "services/auth/localStorageData";
import history from "./../../history";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageUploading from "react-images-uploading";
import { shopper, smallbusiness } from "../../config/config";
import imageCompression from "browser-image-compression";
import Address from "components/Address";
import ListingColor from "common/ListingColor";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SellModel from "components/SellModel";

import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function SellDeals() {
  const classes = useStyles();

  const [sizeData, setSizeData] = React.useState([]);

  const params = useParams();
  console.log(params);
  const [successData, setsuccessData] = useState("");

  const [errorMsg, seterrorMsg] = useState("");
  const [previews, setPreviews] = useState([]);
  const [colors, setColors] = useState([]);
  const [size, setSize] = useState([]);

  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");

  const [address, setaddress] = useState("");
  const [images, setImages] = React.useState([]);
  const maxNumber = 4;
  const options = {
    maxSizeMB: 5,
    maxWidthOrHeight: 550,
    useWebWorker: true,
    fileType: "image/png",
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function changeaddress(value) {
    formik.setFieldValue("address", value);
  }

  const onChangeImage = async (imageList, addUpdateIndex) => {
    // data for submit

    let myallfiles = [];

    for (let i = 0; i < imageList.length; i++) {
      let file = dataURLtoFile(imageList[i].data_url, imageList[i].file.name);

      // let compressedFile = await imageCompression(file, options);

      myallfiles.push(file);
    }
    formik.setFieldValue("pics", myallfiles);

    // let file = dataURLtoFile(images[0].data_url, images[0].file.name);

    // console.log('==========================file name================');

    setImages(imageList);

    ///// formik.setFieldValue('pics', myFiles);
  };

  console.log(params);

  const {
    isError,

    isLoading,

    isSuccess,
    mutate,
  } = useMutation(
    (uploadDeals) =>
      userServices.commonPostService("/uploaddeals", uploadDeals),
    {
      onError: (error) => {
        seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setsuccessData(data.data);
        toast.success("deal successfully posted");
        if (params.uploder == shopper) {
          history.push("/smallbusiness");
        } else {
          history.push("/smallbusiness");
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      productName: "",
      pics: [],
      shopName: "N/A",
      // postCode: '',
      amount: "",
      wasPrice: "",
      nowPrice: "",
      caption: "",

      address: address,
      productUrl: "",
      expireDate: "",
      postageFee: 0,
      category: "",
      // username: "",
    },
    validationSchema: Yup.object().shape({
      productName: Yup.string()
        .min(3, "Must be more than 4 characters")
        .required("Required"),
      // postCode: Yup.string().required('Required'),
      pics: Yup.array().min(1, "please attach at least one picture"),
      amount:
        params.uploder == smallbusiness
          ? Yup.number().required("Required")
          : "",

      // wasPrice: Yup.number().required('Required'),

      nowPrice: Yup.number().required("Required"),

      caption: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      expireDate: Yup.string().required("Required"),

      postageFee:
        params.uploder == smallbusiness
          ? Yup.number().required("Required")
          : "",

      category: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      //  alert(images[0].file.name);
      let finalcolors = colors.map(function (obj) {
        return obj.value;
      });
      console.log(finalcolors);
      console.log(size);
      if (localStorageData("_id")) {
        const formData = new FormData();
        for (const file of values.pics) {
          formData.append("pics", file);
        }
        formData.append("pics", values.pics);
        formData.append("users", localStorageData("_id"));
        Object.keys(values).forEach(function (key) {
          if (key != "pics" || key != "expireDate") {
            formData.append(key, values[key]);
          }
        });
        // tagData.forEach((item) => formData.append("sizes[]", item));
        // finalcolors.forEach((item) => formData.append("color[]", item));

        sizeData.forEach((item) =>
          formData.append("sizes[]", JSON.stringify(item))
        );

        // if (sizeData == '') {
        //   sizeData.forEach((item) =>
        //     formData.append('sizes[]', JSON.stringify(item))
        //   );
        // } else {
        //   formData.append('sizes[]', JSON.stringify(item));
        // }

        formData.append(
          "uplodertype",
          params.uploder == smallbusiness ? smallbusiness : shopper
        );

        formData.append("lat", lat);
        formData.append("long", long);

        // formData.append(
        //   'expireDate',
        //   moment(values.expireDate).format('YYYY-MM-DD')
        // );

        mutate(formData);
      } else {
        toast.error("Login Required");
        history.push("/signin");
      }
    },
  });
  const [tagData, setTagData] = React.useState([]);
  const [data, setData] = React.useState("");
  console.log(tagData);
  const removeTagData = (indexToRemove) => {
    setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
  };
  const addTagData = (event) => {
    if (event.target.value !== "") {
      setTagData([...tagData, data]);
      setData("");
    }
  };
  return (
    <div className="relative pb-32 flex content-center items-center justify-center min-h-screen-75">
      <form
        className=" relative   mb-3 "
        autocomplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Carousel
                thumbWidth={30}
                infiniteLoop={true}
                // showStatus={false}
                width="100%"
                // height='2%'
              >
                {previews.map((file) => (
                  <div>
                    <img src={file.preview} />
                  </div>
                ))}
              </Carousel>
            </Grid>

            <Grid item xs={12}>
              <div className="my-6 ">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImage}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <div className=" flex grid content-center items-center justify-center">
                        <button
                          className="bg-gray-300 p-8 h-40 w-40 rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {formik.values.pics.length != 0 ? (
                          <>
                            <button
                              onClick={onImageRemoveAll}
                              className="btn-styl mx-2 my-2"
                            >
                              Remove all
                            </button>
                          </>
                        ) : null}
                      </div>
                      <Grid container spacing={3}>
                        {imageList.map((image, index) => (
                          <Grid item lg={3} sm={6} xs={6}>
                            <div key={index} className="relative">
                              <img
                                src={image["data_url"]}
                                alt=""
                                className="w-40 h-40 rounded"
                              />
                              <div className="absolute top-3.5 left-1 ">
                                <IconButton
                                  aria-label="delete "
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>

                              <div className="absolute top-3.5 right-0 ">
                                <IconButton
                                  aria-label="delete "
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className="text-center mt-4">Maximum 4 images</div>
            </Grid>
            {formik.touched.pics && formik.errors.pics ? (
              <div className="text-red-600 text-xs">{formik.errors.pics}</div>
            ) : null}

            <Grid item xs={12}>
              <div className=" flex content-center items-center justify-center">
                <input
                  type="text"
                  name="productName"
                  id="productName"
                  className="input-styl h-12 px-2 py-2"
                  placeholder="Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.productName}
                />
              </div>
              {formik.touched.productName && formik.errors.productName ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.productName}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <div className=" ">
                <Address
                  label="Address"
                  setaddress={setaddress}
                  setlat={setlat}
                  changeaddress={changeaddress}
                  setlong={setlong}
                  ////  address={address}
                  // onChange={(e) => setaddress(e.target.value)}
                />
              </div>
              {formik.touched.address && formik.errors.address ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.address}
                </div>
              ) : null}
              {/* {formik.touched.productName && formik.errors.productName ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.productName}
                </div>
              ) : null} */}
            </Grid>

            {params.uploder == shopper ? (
              <Grid item xs={12}>
                <div className=" flex content-center items-center justify-center">
                  <input
                    type="text"
                    className="input-styl h-12 px-2 py-2"
                    placeholder="Deal Url"
                    name="productUrl"
                    id="productUrl"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.productUrl}
                  />
                </div>
                {formik.touched.productUrl && formik.errors.productUrl ? (
                  <div className="text-red-600 text-xs">
                    {formik.errors.productUrl}
                  </div>
                ) : null}
              </Grid>
            ) : null}
            {params.uploder == smallbusiness ? (
              <>
                {/* <label for='colors'>Add color and size:</label> */}
                <SellModel sizeData={sizeData} setSizeData={setSizeData} />

                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
    
                  <div className='tag-input'>
                    <ul className='tags'>
                      {tagData.map((tag, index) => (
                        <li key={index} className='tag'>
                          <span className='tag-title'>{tag}</span>
                          <span
                            className='tag-close-icon'
                            onClick={() => removeTagData(index)}
                          >
                            x
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div className='flex'>
                      <input
                        type='text'
                        className='input-styl px-2 py-2'
                        onChange={(e) => setData(e.target.value)}
                        value={data}
                        // onKeyUp={(event) => (event.keyCode === 32 ? addTagData(event) : null)}
                        placeholder='Size'
                      />
                      <a className='btn-styl ml-6' onClick={addTagData}>
                        Add
                      </a>
                    </div>
                  </div>
                </Grid> */}

                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <label for="colors">Colors:</label>
                  <div className=" flex content-center items-center justify-center">
                    <Select
                      isMulti
                      name="colors"
                      closeMenuOnSelect={false}
                      onChange={setColors}
                      isSearchable={true}
                      label="Single select"
                      options={ListingColor}
                      className="basic-multi-select input-styl"
                      classNamePrefix="select"
                    />
                  </div>

                  {formik.touched.color && formik.errors.color ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.color}
                    </div>
                  ) : null}
                </Grid> */}
              </>
            ) : null}

            <Grid item xs={12}>
              <div className=" flex content-center items-center justify-center">
                <select
                  name="category"
                  id="category"
                  className="input-styl h-12"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                >
                  <option value="" disabled selected>
                    Category
                  </option>
                  {Category.map((s, i) => (
                    <option value={s}>{s}</option>
                  ))}
                </select>
              </div>
              {formik.touched.category && formik.errors.category ? (
                <div className="text-red-600">{formik.errors.category}</div>
              ) : null}
            </Grid>

            {/* <Grid item xs={12}>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='text'
                  className='input-styl h-12 px-2 py-2'
                  placeholder='Shop Name'
                  name='shopName'
                  id='shopName'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shopName}
                />
              </div>
              {formik.touched.shopName && formik.errors.shopName ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.shopName}
                </div>
              ) : null}
            </Grid> */}

            {/* <Grid item xs={6}>
              <label for='colors'>Postcode:</label>
              <div className=' flex content-center items-center justify-center'>
                <input
                  type='text'
                  name='postCode'
                  id='postCode'
                  className='input-styl h-12 px-2 py-2'
                  placeholder='Postcode'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postCode}
                />
              </div>
              {formik.touched.postCode && formik.errors.postCode ? (
                <div className='text-red-600 text-xs'>
                  {formik.errors.postCode}
                </div>
              ) : null}
            </Grid> */}

            <Grid item xs={12}>
              <label for="colors">Expiry date:</label>
              <div className=" flex content-center items-center justify-center">
                <input
                  type="date"
                  placeholder="Expiry date"
                  id="expireDate"
                  // value='2017-06-01'
                  className="input-styl h-12 px-2 py-2"
                  name="expireDate"
                  format="DD-MM-YYYY"
                  onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.expireDate}
                />
              </div>
              {formik.touched.expireDate && formik.errors.expireDate ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.expireDate}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <div className=" flex content-center items-center justify-center">
                <input
                  type="number"
                  name="nowPrice"
                  ////  min='1'
                  id="nowPrice"
                  className="placeholder-gray-600 text-gray-600 bg-white rounded-md text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-2 border-color-primary h-12 px-2 py-2 "
                  placeholder="Product Price"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nowPrice}
                />
              </div>
              {formik.touched.nowPrice && formik.errors.nowPrice ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.nowPrice}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={6}>
              <div className=" flex content-center items-center justify-center">
                <input
                  type="number"
                  name="wasPrice"
                  id="wasPrice"
                  ////   min='1'
                  className="placeholder-gray-600 text-gray-600 bg-white rounded-md text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border-2 border-color-secondary h-12 px-2 py-2 "
                  placeholder="Sale price (optional)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.wasPrice}
                />
                <Tooltip
                  leaveTouchDelay={30}
                  className="text-black"
                  enterTouchDelay={5}
                  title="use this option to list on sale product"
                  placement="top"
                  style={{ marginBottom: 15, marginLeft: 10 }}
                >
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </div>
              {formik.touched.wasPrice && formik.errors.wasPrice ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.wasPrice}
                </div>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <div className=" flex content-center items-center justify-center">
                <textarea
                  type="text"
                  name="caption"
                  id="caption"
                  className="input-styl  px-2 py-2"
                  placeholder="Description "
                  rows="4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.caption}
                ></textarea>
              </div>
              {formik.touched.caption && formik.errors.caption ? (
                <div className="text-red-600 text-xs">
                  {formik.errors.caption}
                </div>
              ) : null}
            </Grid>

            {params.uploder == shopper ? (
              ""
            ) : (
              <>
                <Grid item xs={6}>
                  <label for="colors">
                    Amount:{" "}
                    <Tooltip
                      leaveTouchDelay={30}
                      enterTouchDelay={5}
                      title="Number of items on hand? Once sold, the product will automatically be sold out."
                      placement="top"
                      style={{ marginBottom: 5 }}
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </label>

                  <div className=" flex content-center items-center justify-center">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      min="1"
                      className="input-styl h-12 px-2 py-2"
                      placeholder="Amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                    />
                  </div>
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.amount}
                    </div>
                  ) : null}
                </Grid>{" "}
                <Grid item xs={6}>
                  <label for="colors">Postage fee:</label>
                  <div className=" flex content-center items-center justify-center">
                    <input
                      type="number"
                      name="postageFee"
                      ////  min='1'

                      id="postageFee"
                      className="input-styl h-12 px-2 py-2"
                      placeholder="$"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postageFee}
                    />
                  </div>
                  {formik.touched.postageFee && formik.errors.postageFee ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.postageFee}
                    </div>
                  ) : null}
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <div className=" flex content-center items-center justify-center">
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <button className="btn-styl" type="submit">
                    List
                  </button>
                )}
              </div>
            </Grid>
          </Grid>
          <br />
          {params.uploder == shopper ? (
            <div className="text-sm text-red-600">
              *All listing automatically delete off the site once the expiry
              date it up.
            </div>
          ) : (
            <div className="text-sm text-red-600">
              *Decide when your listing show in the home page, you can activate
              or deactivate your products.
              <br />
              *ouiiwe charges 5% off the payable amount to the small business
              off every transaction.
            </div>
          )}

          {isError && <Alert severity="error">{errorMsg}</Alert>}
          {isSuccess && <Alert severity="success"> {successData}</Alert>}
        </Container>
      </form>
    </div>
  );
}

export default SellDeals;
