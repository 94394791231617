import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import history from './../../history';
const columns = [
  { id: 'counterId', label: 'OrderId', minWidth: 170 },
  { id: 'orderStatus', label: 'order Status', minWidth: 100 },
  {
    id: 'TotalPrice',
    label: 'Total Price $',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },

  {
    id: 'dateGenerated',
    label: 'Date Generated',
    minWidth: 170,
    align: 'right',

    ////format: 'yyyy-MM-dd',
  },
];
var date = moment('2021-09-29T13:41:00.765Z').format('DDMMYYYY h:mm:ss');
console.log(date);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

function Tabble(props) {
  const rows = props.data;

  console.log('------------------rows', rows);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (i) => {
    if (props.from === 'smallbusiness') {
      history.push({
        pathname: '/orderdetail',

        state: { detail: props.data[i], from: 'smallbusiness' },
      });
    } else if (props.from === 'shopper') {
      history.push({
        pathname: '/orderdetail',

        state: { detail: props.data[i], from: 'shopper' },
      });
    }
  };
  return (
    <div className='my-10'>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <div>{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === 'dateGenerated') {
                          return (
                            <div className='m-4'>
                              {moment(row.dateGenerated).format(
                                'DD:MM:YYYY h:mm'
                              )}
                            </div>
                          );
                        } else if (column.id === 'orderStatus') {
                          return (
                            <>
                              {value === 'pending' ? (
                                <div className='text-red-600 font-bold'>
                                  {value}
                                </div>
                              ) : (
                                <div className='text-green-600 font-bold'>
                                  {value}
                                </div>
                              )}
                            </>
                          );
                        } else {
                          return (
                            <TableCell
                              onClick={() => handleClick(i)}
                              key={column.id}
                              align={column.align}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default Tabble;
