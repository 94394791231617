import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { PUBLIC_KEY_Stripe } from 'config/config';
import { Elements } from '@stripe/react-stripe-js';

import PaymentPage from 'components/Card/PaymentPage';

const stripeTestPromise = loadStripe(PUBLIC_KEY_Stripe);
function AddCard() {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentPage />
    </Elements>
  );
}

export default AddCard;
