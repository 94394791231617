import React from 'react';

import {
  Router,
  Route,
  BrowserRouter,
  Switch,
  Redirect,
  Link,
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Navsidebar from './Navsidebar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Orders from './Orders';

import BusinessRequest from './BusinessRequest';
// import Dealtitle from './Dealtitle';
// import Postcode from './Postcode';
// import Dealpostcode from './Dealpostcode';
import Login from './Login';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

function Routes() {
  const classes = useStyles();

  function callme() {
    window.location = '/login';
  }
  return (
    <>
      <div className={classes.root}>
        <Navsidebar />

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth='lg' className={classes.container}>
            <BrowserRouter>
              <Switch>
                {localStorage.getItem('adminlocaldeal') != null ? (
                  <>
                    {JSON.parse(localStorage.getItem('adminlocaldeal')).email ==
                      'Latestlocaldealz@gmail.com' &&
                    JSON.parse(localStorage.getItem('adminlocaldeal')).pass ==
                      'Annie2021!' ? (
                      <>
                        <Route path='/admin/orders' exact component={Orders} />
                        <Route
                          path='/admin/businessrequest'
                          exact
                          component={BusinessRequest}
                        />

                        {/* <Route
                      path='/admin/dealtitle'
                      exact
                      component={Dealtitle}
                    />
                    <Route path='/admin/postcode' exact component={Postcode} />
                    <Route
                      path='/admin/dealpostcode'
                      exact
                      component={Dealpostcode}
                    /> */}
                      </>
                    ) : (
                      callme()
                    )}
                  </>
                ) : (
                  callme()
                )}
              </Switch>
            </BrowserRouter>
          </Container>
        </main>
      </div>

      {/* 
        <Box pt={4}>
            <Copyright />
          </Box> */}
    </>
  );
}

export default Routes;
