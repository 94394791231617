import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import animationData from '../../../assets/json/404.json';
import Lottie from 'react-lottie';
function NotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex grid my-10 mb-40 justify-center	'>
          {/* <img src='https://cdn-icons-png.flaticon.com/512/1325/1325677.png' /> */}
          <Lottie options={defaultOptions} height={150} width={300} />
          <Typography
            variant='h4'
            style={{
              marginTop: 30,
              //   marginLeft: 80,
            }}
          >
            Page Not Found
          </Typography>
        </div>
      </Container>
    </div>
  );
}

export default NotFound;
